import React from 'react';
import { Flex, Text, Stack, Link, Image, Box,
  Tabs, TabList, TabPanels, Tab, TabPanel,
  Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon,
 } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import chBanner from '../Resources/Images/Banners/ch_faq_banner.png';
import enBanner from '../Resources/Images/Banners/en_faq_banner.png';
import CenterGuideBrick from '../Components/Common/CenterGuideBrick';

type AccordionType = {
  content: { question: string, answer: string },
  index: number
};

const FAQPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const diplomaFAQ: string[] = t('diplomaFAQ');
  const teacherFAQ: any[] = t('teacherCertificateFAQ');
  const TeacherAccordion: React.FC<AccordionType> = ({content, index}) => 
    <AccordionItem py='6px' color='text.main' borderBottom='none'>
      <AccordionButton>
        <Text as='span' flex='1' textAlign='left' textStyle='semibold_lg'>
          Q{index + 1}{i18n.language === 'zh' ? '：' : ': '}{content['question']}
        </Text>
        <AccordionIcon boxSize='24px' color='primary.light' />
      </AccordionButton>
      <AccordionPanel pb={4} textStyle='normal_md'>
        A{i18n.language === 'zh' ? '：' : ': '}
        {content['answer'].split('\n').map((ans: string, idx: number) =>
          <Flex key={idx} color='primary.dark' textStyle='normal_md' pl={/^[a-z]\./.test(ans) ? '20px' : '0'}>
            {/^[a-zA-Z0-9]\./.test(ans) ?
              <React.Fragment>
                <Box>{ans.split('\.')[0]}.&nbsp;</Box>
                <Box>{ans.substring(3)}</Box>
              </React.Fragment> :
              <Box>{ans}</Box>
            }
          </Flex>
          
        )}
      </AccordionPanel>
    </AccordionItem>;

  return (
    <Stack w='100%' minH='84vh' direction='column' align='center'>
      <Image w='100%' maxH='301px' objectFit='cover' src={i18n.language === 'zh' ? chBanner : enBanner} alt='教育部標章'/>
      <Flex w='85%' h='100%' py='32px' direction='column'>
        <CenterGuideBrick/>
        <Tabs variant='primary'>
          <TabList>
            <Tab textStyle='semibold_md'>{t('school.degreeCertificate')}</Tab>
            <Tab textStyle='semibold_md'>{t('school.teacherCertificate')}</Tab>
          </TabList>
          <TabPanels>
            <TabPanel py='32px'>
              <Accordion allowMultiple allowToggle>
                {diplomaFAQ.map((content: string, index: number) => 
                  <AccordionItem key={index} py='6px' color='text.main' borderBottom='none'>
                    <AccordionButton>
                      <Text as='span' flex='1' textAlign='left' textStyle='semibold_lg'>
                        Q{index + 1}{i18n.language === 'zh' ? '：' : ': '}{content[0]}
                      </Text>
                      <AccordionIcon boxSize='24px' color='primary.light' />
                    </AccordionButton>
                    <AccordionPanel pb={4} textStyle='normal_md'>
                      A{i18n.language === 'zh' ? '：' : ': '}
                      {content[1].split('\n').map((ans: string, idx: number) => {
                          const linkWord = t('navbar.issuanceUnits').toLocaleLowerCase();
                          if (ans.includes(linkWord)) {
                            const substr = ans.split(linkWord);
                            return (
                              <Flex key={idx}>
                                <Text>{substr[0]}&nbsp;</Text>
                                <Link color='#00A2AE' textDecoration='underline' onClick={() => history.push('/school')}>
                                  {linkWord}
                                </Link>
                                <Text>&nbsp;{substr[1]}</Text>
                              </Flex>
                            );
                          }
                          return <Text key={idx} color='primary.dark' textStyle='normal_md'>{ans}</Text>;
                        })}
                    </AccordionPanel>
                  </AccordionItem>
                )}
              </Accordion>
            </TabPanel>
            <TabPanel py='32px'>
              <Text mb='24px' fontSize='24px' fontWeight='700' lineHeight='133%' letterSpacing='0.24px' color='primary.light'>
                {t('teacherCertificateType')[0]}
              </Text>
              <Accordion allowMultiple allowToggle>
                {teacherFAQ.slice(0, 5).map((content: any, index: number) =>
                  <TeacherAccordion key={index} content={content} index={index} />
                )}
              </Accordion>
              <Text mt='44px' mb='24px' fontSize='24px' fontWeight='700' lineHeight='133%' letterSpacing='0.24px' color='primary.light'>
                {t('teacherCertificateType')[1]}
              </Text>
              <Accordion allowMultiple allowToggle>
                {teacherFAQ.slice(5, 7).map((content: any, index: number) =>
                  <TeacherAccordion key={index + 5} content={content} index={index + 5} />
                )}
              </Accordion>
              <Text mt='44px' mb='24px' fontSize='24px' fontWeight='700' lineHeight='133%' letterSpacing='0.24px' color='primary.light'>
                {t('teacherCertificateType')[2]}
              </Text>
              <Accordion allowMultiple allowToggle>
                {teacherFAQ.slice(7, 10).map((content: any, index: number) =>
                  <TeacherAccordion key={index + 7} content={content} index={index + 7} />
                )}
              </Accordion>
              <Text mt='44px' mb='24px' fontSize='24px' fontWeight='700' lineHeight='133%' letterSpacing='0.24px' color='primary.light'>
                {t('teacherCertificateType')[3]}
              </Text>
              <Accordion allowMultiple allowToggle>
                {teacherFAQ.slice(10, 13).map((content: any, index: number) =>
                  <TeacherAccordion key={index + 10} content={content} index={index + 10} />
                )}
              </Accordion>
              <Text mt='44px' mb='24px' fontSize='24px' fontWeight='700' lineHeight='133%' letterSpacing='0.24px' color='primary.light'>
                {t('teacherCertificateType')[4]}
              </Text>
              <Accordion allowMultiple allowToggle>
                {teacherFAQ.slice(13).map((content: any, index: number) =>
                  <TeacherAccordion key={index + 13} content={content} index={index + 13} />
                )}
              </Accordion>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </Stack>
  )
};

export default FAQPage;