import React from 'react';
import { Flex, Text, Stack, Image, Heading } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next';

import chBanner from '../Resources/Images/Banners/ch_policy_banner.png';
import enBanner from '../Resources/Images/Banners/en_policy_banner.png';
import CenterGuideBrick from '../Components/Common/CenterGuideBrick';

const PrivacyPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const contents: string[] = t('privacy');
  const chineseContent = 
    <React.Fragment>
      <Text textStyle='normal_md'>{contents[0]}</Text>
      <Text mt='24px' mb='12px' color='primary.dark' textStyle='semibold_md'>{contents[1]}</Text>
      <Text textStyle='normal_md'>{contents[2]}</Text>
      <Text mt='24px' mb='12px' color='primary.dark' textStyle='semibold_md'>{contents[3]}</Text>
      <Text textStyle='normal_md'>{contents[4]}</Text>
      <Text textStyle='normal_md'>{contents[5]}</Text>
      <Text textStyle='normal_md'>{contents[6]}</Text>
      <Text textStyle='normal_md'>{contents[7]}</Text>
      <Flex direction='column' pl={8}>
        {i18n.language === 'zh' && contents[8].split('\n').map(content =>
          <Text textStyle='normal_md' key={content}>{content}</Text>
        )}
      </Flex>
      <Text textStyle='normal_md'>{contents[9]}</Text>
      <Flex direction='column' pl={8}>
        {i18n.language === 'zh' && contents[10].split('\n').map(content =>
          <Text textStyle='normal_md' key={content}>{content}</Text>
        )}
      </Flex>
      <Text textStyle='normal_md'>{contents[11]}</Text>
      <Text mt='24px' mb='12px' color='primary.dark' textStyle='semibold_md'>{contents[12]}</Text>
      <Text textStyle='normal_md'>{contents[13]}</Text>
      <Flex direction='column' pl={8}>
        {i18n.language === 'zh' && contents[14].split('\n').map(content =>
          <Text textStyle='normal_md' key={content}>{content}</Text>
        )}
      </Flex>
      <Text mt='24px' mb='12px' color='primary.dark' textStyle='semibold_md'>{contents[15]}</Text>
      <Flex direction='column' pl={0}>
        {i18n.language === 'zh' && contents[16].split('\n').map(content =>
          <Text textStyle='normal_md' key={content}>{content}</Text>
        )}
      </Flex>
      <Flex direction='column' pl={0}>
        {i18n.language === 'zh' && contents[17].split('\n').map(content =>
          <Text textStyle='normal_md' key={content}>{content}</Text>
        )}
      </Flex>
      <Text mt='24px' mb='12px' color='primary.dark' textStyle='semibold_md'>{contents[18]}</Text>
      <Text textStyle='normal_md'>{contents[19]}</Text>
    </React.Fragment>
  const englishContent = 
    <Flex direction='column' gap='12px'>
      {contents.map((content: string, index: number) => index % 2
        ? <Text mt='12px' color='primary.dark' textStyle='semibold_md' key={content}>{content}</Text>
        : <Stack key={content}>
          {content.split('\n').map(c =>
            <Text textStyle='normal_md' key={c}>{c}</Text>
          )}
        </Stack>)
      }
    </Flex>
    
  return (
    <Stack w='100%' minH='84vh' direction='column' align='center'>
      <Image w='100%' maxH='301px' objectFit='cover' src={i18n.language === 'zh' ? chBanner : enBanner} alt='教育部標章'/>
      <Flex w='85%' h='100%' py='32px' direction='column'>
        <CenterGuideBrick/>
        <Heading as='h2' variant='lg_normal' mb='32px'>{t('footer.privacy')}</Heading>
        {i18n.language === 'zh' ? chineseContent : englishContent}
      </Flex>
    </Stack>
  )
};

export default PrivacyPage;