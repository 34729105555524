import React, { useState, useEffect } from 'react';
import { Button, Flex, Stack, Text, Tr, Td,
  Input, InputLeftElement, InputGroup } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';

import { Hashtag } from '../../Components/upload/BulletinBoard';
import PaginationTable from '../../Components/Common/PaginationTable';
import { getBulletinList } from '../../Utils/frontStageApi';
import { BulletinListType } from '../../Utils/backStageApi';
import { checkAuth } from '../../Utils/common';

const BulletinManagerPage: React.FC = () => {
  const history = useHistory();
  const [bulletins, setBulletins] = useState<BulletinListType>();
  const [textFilter, setTextFilter] = useState<string>('');
  const [selectedTag, setSelectedTag] = useState<string[]>(['一般公告', '合作學校', '系統維護']);
  
  const goCreatePage = () => {
    history.push('/bs/createbullentin');
  }
  const goEditPage = (announcementId: number) => {
    history.push('/bs/editbullentin', { bid: announcementId });
  }
  let rowData = bulletins ? bulletins
    .filter(item => item.title.includes(textFilter))
    .filter(item => selectedTag.some(tag => item.tag.includes(tag)))
    .map(item => (
      <Tr key={item.bid}>
        <Td p='10px 12px' color='text.main' textStyle='semibold_sm'>
          {item.date}
        </Td>
        <Td p='10px 12px' color='text.main' textStyle='semibold_sm'>
          {item.title}
        </Td>
        <Td p='6px 10px' color='text.main' textStyle='bold_sm' borderBottom='none'>
          <Flex gap='25px'>
            <Hashtag text={item.tag}/>
            <Button p='8px 12px' bg='white' color='primary.main'
              border='1px solid' borderColor='primary.main'
              onClick={() => goEditPage(item.bid)}
            >
              編輯
            </Button>
          </Flex>
        </Td>
      </Tr>
    )) : [];
  const switchTag = (tag: string) => {
    if(selectedTag.includes(tag))
      setSelectedTag(preTag => {
        let newTag = [...preTag];
        newTag = newTag.filter(item => item !== tag);
        return newTag;
      });
    else
      setSelectedTag(preTag => {
        let newTag = [...preTag];
        newTag.push(tag);
        return newTag;
      });
  };
  
  const TagButton: React.FC<{ tag: string }> = ({ tag }) => (
    <Button variant='outlinedSecondary' p='8px 12px' border='1px solid #A8B8C2'
      isActive={selectedTag.includes(tag)} onClick={() => switchTag(tag)}
    >
      {tag}
    </Button>
  );

  useEffect(() => {
    checkAuth();
    getBulletinList(0)
    .then((data: any) => {
      if (data && data.list) {
        setBulletins(data.list);
      }
    });
  }, []);

  return (
    <Stack w='100%' spacing='72px'>
      {/* Title */}
      <Flex justify='space-between' alignItems='center'>
        <Text color='primary.main' fontWeight='700' fontSize='30px' lineHeight='133%'>計畫公告管理</Text>
        <Button p='8px 12px' onClick={goCreatePage}>新增</Button>
      </Flex>
      {/* Bulletin Information */}
      <Stack mt='70px' spacing='35px'>
        {/* Text Filter */}
        <InputGroup w='min(475px, 100%)'>
          <InputLeftElement children={<SearchIcon color='primary.main' />} />
          <Input
            placeholder='請輸入標題進行搜尋'
            value={textFilter}
            onChange={e => setTextFilter(e.target.value)}
          />
        </InputGroup>
        {/* Type Filter */}
        <Flex justify='start' gap='20px'>
          <TagButton tag='一般公告' />
          <TagButton tag='合作學校' />
          <TagButton tag='系統維護' />
        </Flex>
        {/* Announcement List */}
        <PaginationTable
          colNames={['公告日期', '公告主旨', '公告分類']}
          listItems={rowData}
        />
      </Stack>
    </Stack>
  );
}

export default BulletinManagerPage;

