import React, { useEffect, ChangeEvent } from 'react';
import { Flex, Box, Text, Button, Stack, useDisclosure,
  Input, InputGroup, InputRightElement,
  FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { SmallCloseIcon, DeleteIcon } from '@chakra-ui/icons';

import { SimpleModal } from '../Common/Modal';
import { DocumentFormType } from '../../Utils/backStageApi';

interface Props {
  initData?: DocumentFormType,
  setFormData: React.Dispatch<React.SetStateAction<DocumentFormType | undefined>>;
}

const FileEditer: React.FC<Props> = ({ initData, setFormData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const uploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const acceptedFileTypes = ['.pdf', '.doc', '.docx'];
    const file = event.target.files && event.target.files[0];
    if(file){
      if(acceptedFileTypes.some(type => file.name.endsWith(type)))
        formik.setFieldValue('file', file);
      else
        onOpen();
    }
  }
  const deleteFile = () => {
    formik.setFieldValue('file', null);
  }
  function downloadFile(file: File | null) {
    if(file){
      const url = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.name;

      document.body.appendChild(a);
      a.click();
    
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  }

  const validate = (values: any) => {
    const errors: any = {};
    if(!values.title)
      errors.title = '請輸入標題';
    if(!values.file)
      errors.file = '請上傳檔案';

    return errors;
  }
  const onSubmit = () => {};
  const formik = useFormik<DocumentFormType>({
    initialValues: { 
      did: -1,
      title: '',
      file: null 
    },
    validate,
    onSubmit,
  });

  useEffect(() => {
    if (initData) {
      formik.setValues(initData);
    }
  }, [initData]);
  useEffect(() => {
    setFormData(formik.values);
  }, [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing='50px'>
        <Flex gap='30px' align='center'>
          <Text minW='50px' fontWeight='600' fontSize='24px' lineHeight='133%' >
            標題
          </Text>
          <FormControl isInvalid={!!formik.errors.title}>
            <InputGroup>
              <Input
                type='text'
                name='title'
                placeholder=''
                size='lg'
                onChange={formik.handleChange}
                value={formik.values.title}
              />
              {formik.values.title && (
                <InputRightElement
                  color='primary.main'
                  cursor={'pointer'}
                  onClick={() => formik.setFieldValue('title', '')}
                  children={<SmallCloseIcon color='primary.main' />}
                />
              )}
            </InputGroup>
            <FormErrorMessage>{formik.errors.title}</FormErrorMessage>
          </FormControl>
        </Flex>
        <Flex gap='30px' align='center'>
          <Text minW='50px' fontWeight='600' fontSize='24px' lineHeight='133%' >
            檔案
          </Text>
          {formik.values.file ?
            <Flex gap='10px' justify='end'>
              <Text textStyle='semibold_sm' color='primary.dark' 
                textDecoration='underline' cursor='pointer' isTruncated
                onClick={() => downloadFile(formik.values.file)}
              >
                {formik.values.file.name}
              </Text>
              <DeleteIcon boxSize='16px' color='primary.main'
                cursor='pointer' onClick={() => deleteFile()}
              />
            </Flex> :
            <FormControl isInvalid={!!formik.errors.file}>
              <Box position='relative' overflow='hidden'>
                <Button p='8px 12px'>瀏覽檔案</Button>
                <Input type='file' position='absolute' left='0' top='0' opacity='0' 
                  cursor='pointer' onChange={e => uploadFile(e)} accept=".pdf, .doc, .docx"/>
              </Box>
              <FormErrorMessage>{formik.errors.file}</FormErrorMessage>
            </FormControl>
          }
        </Flex>
      </Stack>
      <SimpleModal
        isOpen={isOpen}
        onClose={onClose}
        header={<Text textStyle='semibold_lg' color='critical.300'>檔案格式不支援</Text>}
      />
    </form>
  );
};

export default FileEditer;
