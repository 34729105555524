import React, { useState, useEffect } from 'react';
import { Flex, Text, Box, Button, useDisclosure, useToast,
  Input, InputGroup, InputRightElement, InputLeftElement,
  FormControl, FormErrorMessage, Modal, ModalOverlay, ModalContent, 
  ModalHeader, ModalFooter, ModalBody } from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon, ArrowBackIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';

import BulletinEditer from '../../Components/backstage/BulletinEditer';
import { CancelModal } from '../../Components/Common/Modal';
import { BulletinType, editBulletin } from '../../Utils/backStageApi';
import { checkAuth } from '../../Utils/common';

interface CancelEdit {
  isOpen: boolean;
  onClose: () => void;
}

interface ConfirmCreate {
  isOpen: boolean;
  onClose: () => void;
  password: Password;
  setPassword: React.Dispatch<React.SetStateAction<Password>>;
  createFunc: () => void;
}

type Password = {
  text: string;
  visible: boolean;
  isError: boolean;
  errorMsg: string;
}

export const CancelEditModal: React.FC<CancelEdit> = ({isOpen, onClose}) => {
  const history = useHistory();

  return (
    <CancelModal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <Text textStyle='semibold_lg' color='text.main'>
          有內容尚未儲存，確定離開？
        </Text>
      }
      confirmButtonName='離開'
      confirmButtonFunc={() => history.goBack()}
      cancelButtonName='繼續編輯'
    />
  )
}

export const ConfirmCreateModal: React.FC<ConfirmCreate> = ({
  isOpen,
  onClose,
  password,
  setPassword,
  createFunc
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl' isCentered>
      <ModalOverlay />
      <ModalContent maxW='540px'>
        <ModalHeader p='24px 24px 16px 24px'>
          <Text textStyle='semibold_lg' color='text.main'>
            請輸入密碼進行上稿
          </Text>
        </ModalHeader>
        <ModalBody p='0 24px 16px 24px' color='grey.700'>
          <FormControl w='80%' isInvalid={password.isError}>
            <InputGroup>
              <InputLeftElement
                color='text.main'
                children={<Text textStyle='semibold_sm'>密碼</Text>}
              />
              <Input
                type={password.visible ? 'text' : 'password'}
                name='password'
                placeholder='請輸入密碼'
                onChange={e => setPassword(prev => ({...prev, text: e.target.value}))}
                value={password.text}
              />
              {password && (
                <InputRightElement
                  color='primary.main'
                  cursor={'pointer'}
                  onClick={() => setPassword(prev => ({...prev, visible: !prev.visible}))}
                  children={password.visible ? <ViewOffIcon color='primary.main' /> : <ViewIcon color='primary.main' />}
                />
              )}
            </InputGroup>
            <FormErrorMessage>{password.errorMsg}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter p='0 24px 24px 24px' gap='8px'>
          <Button variant='secondary' onClick={onClose}>
            返回
          </Button>
          <Button
            onClick={createFunc}
            disabled={!password.text}
          >
            新增
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const CreateBulletinPage: React.FC = () => {
  const history = useHistory();
  const toast = useToast();
  const [formData, setFormData] = useState<BulletinType>();
  const [password, setPassword] = useState({
    text: '',
    visible: false,
    isError: false,
    errorMsg: '密碼錯誤'
  });
  const {isOpen: cancelIsOpen, onOpen: openCancel, onClose: closeCancel} = useDisclosure();
  const {isOpen: confirmIsOpen, onOpen: openConfirm, onClose: closeConfirm} = useDisclosure();

  const cancelEdit = () => {
    if(formData?.title || formData?.text)
      openCancel();
    else
      history.goBack();
  }
  const createBulletin = () => {
    setPassword(prev => ({...prev, isError: false}));
    
    if(formData){
      editBulletin(password.text, formData.tag, formData.title, formData.text)
      .then(response => {
        if(response && response.applied === true){
          closeConfirm();
          toast({
            title: '公告已新增',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          history.goBack();
        }
        else{
          setPassword(prev => ({...prev, isError: true}));
          if(response?.error_msg){
            setPassword(prev => ({...prev, errorMsg: response.error_msg}));
          }
        }
      })
      .catch(error => {
        setPassword(prev => ({...prev, isError: true}));
        if(error && error.error_msg){
          setPassword(prev => ({...prev, errorMsg: error.error_msg}));
        }
      });
    }
  }

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <Flex w='100%' justify='center' alignItems='center'>
      <Flex maxW='679px' w='100%' direction='column'>
        <Flex width='fit-content' gap='10px' alignItems='center' onClick={cancelEdit} cursor='pointer'>
          <ArrowBackIcon boxSize='24px' color='primary.light' />
          <Text color='primary.light' textStyle='semibold_lg'>返回</Text>
        </Flex>
        <Box mt='20px'>
          <Text fontWeight='700' fontSize='30px' lineHeight='133%' color='primary.main'>
            新增公告
          </Text>
        </Box>
        <Box mt='75px'>
          <BulletinEditer
            setFormData={setFormData}
          />
        </Box>
        <Flex mt='60px' justify='center' gap='30px'>
          <Button variant='outlinedCritical' p='8px 12px' 
            border='1px solid' onClick={cancelEdit}
          >
            取消
          </Button>
          <Button p='8px 12px' onClick={openConfirm}
            disabled={!formData?.title || !formData?.text}
          >
            新增
          </Button>
        </Flex>
      </Flex>
      <CancelEditModal 
        isOpen={cancelIsOpen} 
        onClose={closeCancel} 
      />
      <ConfirmCreateModal
        isOpen={confirmIsOpen}
        onClose={closeConfirm}
        password={password}
        setPassword={setPassword}
        createFunc={() => createBulletin()}
      />
    </Flex>
  );
};

export default CreateBulletinPage;
