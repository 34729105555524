import React from 'react';
import { Flex, Stack, Text, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import useMedia from '../../Utils/useMedia';
import icon1 from '../../Resources/Images/Icons/icon1.svg';
import icon2 from '../../Resources/Images/Icons/icon2.svg';
import icon3 from '../../Resources/Images/Icons/icon3.svg';
import step1 from '../../Resources/Images/Banners/step1.png';
import step2 from '../../Resources/Images/Banners/step2.png';
import step3 from '../../Resources/Images/Banners/step3.png';

const Instruction: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile, isDesktop, isTablet } = useMedia();
  const stepContents = [
    { icon: icon1, title: t('upload.step1title'), info: t('upload.step1info'), image: step1 },
    { icon: icon2, title: t('upload.step2title'), info: t('upload.step2info'), image: step2 },
    { icon: icon3, title: t('upload.step3title'), info: t('upload.step3info'), image: step3 },
  ];

  const stepBlock = () => {
    return (
      stepContents.map((content, index) => (
        <Stack key={index + content.title} w={isDesktop ? '30%' : '100%'} mb={isDesktop || index == 2 ? '0px' : '16px'}>
          <Flex pb='10px' align='center'>
            <Image src={content.icon} w='32px' mr='8px' alt={`instruction ${index + 1}`} />
            <Text textStyle='bold_lg'>{content.title}</Text>
          </Flex>
          <Text mt='14px'>{content.info}</Text>
          <Image maxW='350px' src={content.image} w='100%' mt='10px' alt='instruction icon' />
        </Stack>
      ))
    );
  };

  return (
    <Stack mt='50px' spacing='30px'>
      <Flex w='100%' justify='start'>
        <Text textStyle='semibold_lg' color='secondary.main'>{t('upload.helpTitle')}</Text>
      </Flex>
      {isDesktop  && <Flex w='100%' justify='space-between'>{stepBlock()}</Flex>}
      {!isDesktop && <Flex w='100%' direction='column'>{stepBlock()}</Flex>}
    </Stack>
  );
};

export default Instruction;