import axios, { AxiosResponse } from 'axios';

enum Estatus {
  upload = 0,
  status,
  result
}

enum EuploadStatus {
  normal = 0,
  fail,
  overSize
}

interface ApiResponse {
  applied: boolean;
  error_msg: string;
}

interface StatisticResponse extends ApiResponse {
  dd_cat_num: number;
  dd_issuer_num: number;
  dd_issued_cnt: number;
  dd_verify_cnt: number;
}

interface BulletinResponse extends ApiResponse {
  count?: number;
  list?: { bid: number; date: string; title: string; tag: string }[];
  content?: { bid: number; date: string; title: string; tag: string; text: string };
}

interface DocumentResponse extends ApiResponse {
  count?: number;
  list?: { did: number; date: string; title: string; link: string }[];
}

interface Metadata {
  chinese_name: string,
  student_id: string,
  graduate_univ: string,
  graduate_colg: string,
  graduate_dept: string,
  AATL: string
}

const MAIN_HOST = (window as any).env.MAIN_HOST;

/**
 * Fetches an authentication token from the server.
 *
 * @param setToken - Callback function to set the token once fetched.
 */
export const getToken = async (
  setToken: (token: string) => void
): Promise<void> => {
  try {
    const response = await fetch(`${MAIN_HOST}/api/token`, {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    });
    const token = await response.text();
    setToken(token);
  } catch (err) {
    console.error(err);
  }
}

/**
 * Submits a file to the server for validation.
 *
 * @param file - The file to be uploaded.
 * @param token - The authentication token.
 * @param setLoading - Callback function to set the loading state.
 * @param setStatus - Callback function to set the status.
 * @param setResult - Callback function to set the result.
 * @param setUploadStatus - Callback function to set the upload status.
 */
export const submitFile = async (
  file: File | undefined,
  token: string,
  setLoading: (result: boolean) => void,
  setStatus: (status: Estatus) => void,
  setResult: (result: number) => void,
  setMetadata: (result: Metadata) => void,
  setUploadStatus: (status: EuploadStatus) => void
): Promise<void> => {
  if (!file) { 
    setUploadStatus(EuploadStatus.fail);
    setLoading(false);
    return;
  } else if (file.size / 1048576 > 2) {
    setUploadStatus(EuploadStatus.overSize);
    setLoading(false);
    return;
  }

  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await fetch(`${MAIN_HOST}/students/validate/`, {
      body: formData,
      mode: 'cors',
      method: 'POST',
      headers: {
        Authorization: `jwt ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
      credentials: 'include',
    });
    const res = await response.json();

    const result2num = ['success', 'expired', 'failure'];

    if (!res || !res.error_msg) {
      setStatus(Estatus.upload);
    } else {
      setResult(result2num.indexOf(res.error_msg));
      if (res.metadata)
        setMetadata({
          chinese_name: res.metadata.chinese_name !== undefined ? res.metadata.chinese_name : '',
          student_id: res.metadata.student_id !== undefined ? res.metadata.student_id : '',
          graduate_univ: res.metadata.graduate_univ !== undefined ? res.metadata.graduate_univ : '',
          graduate_colg: res.metadata.graduate_colg !== undefined ? res.metadata.graduate_colg : '',
          graduate_dept: res.metadata.graduate_dept !== undefined ? res.metadata.graduate_dept : '',
          AATL: res.metadata.AATL !== undefined ? res.metadata.AATL : ''
        });
      else
        setMetadata({
          chinese_name: '',
          student_id: '',
          graduate_univ: '',
          graduate_colg: '',
          graduate_dept: '',
          AATL: ''
        });
      setStatus(Estatus.result);
    }
  } catch (err) {
    console.error(err);
  } finally {
    setLoading(false);
  }
}

/**
 * Fetches a summary of student statistics from the server.
 *
 * @returns StatisticResponse if the response data matches the StatisticResponse type, otherwise undefined.
 */
export const getSummary = async(): Promise<StatisticResponse | undefined> => {
  const url = `${MAIN_HOST}/students/summary/`;
  function isStatisticData(data: any): data is StatisticResponse {
    return data.applied !== undefined && data.error_msg !== undefined;
  }

  try {
    const response: AxiosResponse = await axios.get(url);

    if (isStatisticData(response.data)) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
}

function isBulletinData(data: any): data is BulletinResponse {
  return data.applied !== undefined && data.error_msg !== undefined;
}

/**
 * Get bulletin data from server.
 *
 * @param bid - When op is 2, specify the number of the detailed content of the bulletin to be returned
 * @param op - Operation mode (1: Returns a list of bulletins without content; 
 * 2: Returns all fields of a specific bulletin, including content)
 *
 * @returns BulletinData if the response data matches the BulletinData type, otherwise undefined
 */
export const getBulletin = async(bid: number, op: number = 2): Promise<BulletinResponse | undefined> => {
  const url = `${MAIN_HOST}/students/bulletin/`;
  const params = { bid, op };

  try {
    const response: AxiosResponse = await axios.get(url, { params });

    if (isBulletinData(response.data)) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get bulletin list from server.
 *
 * @param cnt - When op is 1, specify the length of the returned bulletin list. 
 * If it is 0, the list of all bulletins is returned. 
 * The list is sorted by date, with the newest at the front
 * @param op - Operation mode (1: Returns a list of bulletins without content; 
 * 2: Returns all fields of a specific bulletin, including content)
 *
 * @returns BulletinData if the response data matches the BulletinData type, otherwise undefined
 */
export const getBulletinList = async(cnt: number, op: number = 1): Promise<BulletinResponse | undefined> => {
  const url = `${MAIN_HOST}/students/bulletin/`;
  const params = { cnt, op };

  try {
    const response: AxiosResponse = await axios.get(url, { params });

    if (isBulletinData(response.data)) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get document data from server.
 *
 * @param cnt - Specifies the length of the document list to return. 
 * If it is 0, the list of all documents is returned. 
 * The list is sorted by date, with the newest at the front
 *
 * @returns DocumentResponse if the response data matches the DocumentResponse type, otherwise undefined
 */
export const getDocument = async (cnt: number): Promise<DocumentResponse | undefined> => {
  const url = `${MAIN_HOST}/students/document/`;
  const params = { cnt };
  function isDocumentResponse(data: any): data is DocumentResponse {
    return data.applied !== undefined && data.error_msg !== undefined;
  }

  try{
    const response: AxiosResponse = await axios.get(url, { params });
    
    if (isDocumentResponse(response.data)) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
}
