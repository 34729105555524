import React from 'react';
import { Text, Stack, Image, Heading, useDisclosure, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import chBanner from '../Resources/Images/Banners/ch_sitemap_banner.png';
import enBanner from '../Resources/Images/Banners/en_sitemap_banner.png';
import { ContactModal } from '../Components/general/Footer';
import CenterGuideBrick from '../Components/Common/CenterGuideBrick';

const SitemapPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const links = [
    { path: '/about', text: t('navbar.about') },
    { path: '/upload', text: t('navbar.verification') },
    { path: '/news', text: t('navbar.news') },
    { path: '/FAQ', text: t('navbar.FAQ') },
    { path: '/school', text: t('navbar.issuanceUnits') },
    { path: '/sitemap', text: t('footer.sitemap') },
    { path: '#', text: t('footer.contact') },
    { path: '/privacy', text: t('footer.privacy') },
  ];

  return (
    <Stack w='100%' minH='84vh' direction='column' align='center'>
      <Image w='100%' maxH='301px' objectFit='cover' src={i18n.language === 'zh' ? chBanner : enBanner} alt='教育部標章'/>
      <Flex w='85%' h='100%' py='32px' direction='column'>
        <CenterGuideBrick/>
        <Heading as='h2' variant='lg_normal'>{t('sitemap.accesskeyTitle')}</Heading>
        <Stack mt='40px' textStyle='normal_sm'>
          <Text>{t('sitemap.siteblock')}</Text>
          <Text pt='20px'>{t('sitemap.accesskey')[0]}</Text>
          {(t('sitemap.accesskey') as string[]).slice(1, 4).map(text => 
            <Text>． {text}</Text>
          )}
          <Text pt='20px'>{t('sitemap.browser')}</Text>
        </Stack>
        <Heading as='h2' variant='lg_normal' mt='65px'>{t('sitemap.sitemap')}</Heading>
        <Stack mt='40px' textStyle='normal_sm'>
          {links.filter(link => link.text !== '').map((link, index) => (
            <Flex key={index} w='fit-content' letterSpacing='1px' cursor='pointer' 
              onClick={link.text === t('footer.contact') ? onOpen : undefined}
            >
              <NavLink exact to={link.path} key={index}>
                <Text display='inline'>{index + 1}. </Text>
                <Text display='inline' _hover={{fontWeight: 600}} decoration='underline'>{link.text}</Text>
              </NavLink>
            </Flex>
          ))}
        </Stack>
      </Flex>
      <ContactModal
        isOpen={isOpen}
        onClose={onClose}
      />
    </Stack>
  )
};

export default SitemapPage;