import { extendTheme } from '@chakra-ui/react';
import styles from './styles';
import colors from './colors';
import textStyles from './textStyles';
import Button from './components/button';
import Tabs from './components/tabs';
import Heading from './components/heading';

// eslint-disable-next-line
export default extendTheme({
  fonts: {
    body: 'Open Sans, Roboto, PingFang HK',
  },
  components: {
    Heading,
    Button,
    Tabs,
  },
  styles,
  colors,
  textStyles,
  
})