import React, { useState, useEffect } from 'react';
import { Flex, Stack, Box, Text, Tr, Td, Link } from '@chakra-ui/react';

import PaginationTable from '../Common/PaginationTable';
import { getDocument } from '../../Utils/frontStageApi';
import { DocumentType } from '../../Utils/backStageApi';

const Document: React.FC = () => {
  const [documents, setDocuments] = useState<DocumentType[]>();

  let rowData = documents ? 
    documents.map(item => (
      <Tr key={item.did}>
        <Td p='10px 12px' color='text.main' textStyle='semibold_sm'>
          {item.date}
        </Td>
        <Td p='10px 12px' color='text.main' textStyle='semibold_sm'>
          <Link href={item.link} target='_blank'>{item.title}</Link>
        </Td>
      </Tr>
    )) : [];

  useEffect(() => {
    getDocument(0)
    .then((data: any) => {
      if (data && data.list) {
        data.list.map((item: DocumentType) =>{
          item.link = (window as any).env.MAIN_HOST + item.link;
          return item;
        });
        setDocuments(data.list);
      }
    });
  }, []);

  return (
    <Stack w='100%' p='58px 80px' my='40px' spacing='24px' bg='#DDEEE8' borderRadius='30px'>
      <Flex justify='start' alignItems='center'>
        <Flex gap='5px' alignItems='center'>
          <Box w='10px' h='15px' bg='#2A7157' borderRadius='5px' />
          <Text textStyle='semibold_lg' color='#2A7157'>文件下載</Text>
        </Flex>
      </Flex>
      <PaginationTable
        colNames={['公告日期', '公告檔案']}
        listItems={rowData}
      />
    </Stack>
  )
};

export default Document;