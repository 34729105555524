const colors = {
  selected: '#078385',
  text: {
    main: '#00292B',
    colored: '#27496D',
  },
  teacher: {
    primary: '#2A7157',
    light: '#5AA388'
  },
  primary: {
    light: '#4986B0',
    main: '#35658D',
    dark: '#27496D',
    darker: '#1D3957',
  },
  secondary: {
    light: '#00BDC7',
    main: '#00B2C0',
    dark: '#008E96',
    darker: '#004543',
  },
  additional:{
    primary: {
      light: {
        default: '#F1F9FC',
        hover: '#E5F1F6',
        active: '#BFDCE9',
      },
      dark: {
        default: '#4986B0',
        hover: '#3D759F',
        active: '#BFDCE9',
        disabled: '#7BB1CD'
      },
    },
    secondary: {
      light: {
        default: '#FFFFFF',
        hover: '#F3F5F6',
        active: '#DAE0E5',
      },
      dark: {
        default: '#DAE0E5',
        hover: '#C1CCD1',
        active: '#A8B8C2',
        disabled: '#C1CCD1'
      },
    },
    primary_greytone: {
      light: {
        default: '#FFFFFF',
        hover: '#F3F5F6',
        active: '#DAE0E5',
      },
      dark: {
        default: '#DAE0E5',
        hover: '#C1CCD1',
        active: '#A8B8C2',
        disabled: '#C1CCD1'
      },
    }
  },
  grey: {
    light: '#e0e0e0',
    dark: '#6c6c6c',
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
  },
  info: {
    50: '#E8F4FD',
    100: '#D0E9FB',
    200: '#B4DBF8',
    300: '#0172CB',
    400: '#005AA3',
    500: '#01508E',
  },
  warning: {
    50: '#FDF0E3',
    100: '#FAE2C7',
    200: '#E98305',
    300: '#DC7C05',
    400: '#CD7304',
    500: '#AE5700',
  },
  success: {
    50: '#EBF4EC',
    100: '#D7EAD9',
    200: '#C3DFC7',
    300: '#28A138',
    400: '#1D7228',
    500: '#2D7738',
  },
  critical: {
    50: '#fbcdd1',
    100: '#f79997',
    200: '#f0716e',
    300: '#d21c1b',
    400: '#c4040b',
    500: '#9d1615',
  }
};

export default colors;
