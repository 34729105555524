import React from 'react';
import { ChakraProvider, CSSReset, Flex } from '@chakra-ui/react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import theme from './Styles/theme';
import './i18n';

import Navbar from './Components/general/Navbar';
import Footer from './Components/general/Footer';
import BackStageNavbar from './Components/general/BackStageNavbar';
import BackStageFooter from './Components/general/BackStageFooter';
import AboutPage from './Pages/AboutPage';
import UploadPage from './Pages/UploadPage';
import SchoolPage from './Pages/SchoolPage';
import NewsPage from './Pages/NewsPage';
import FAQPage from './Pages/FAQPage';
import SitemapPage from './Pages/SitemapPage';
import PrivacyPage from './Pages/PrivacyPage';
import Page404 from './Pages/Page404';
import LoginPage from './Pages/Backstage/LoginPage';
import StatisticPage from './Pages/Backstage/StatisticPage';
import BulletinManagerPage from './Pages/Backstage/BulletinManagerPage';
import FileManagerPage from './Pages/Backstage/FileManagerPage';
import CreateBulletinPage from './Pages/Backstage/CreateBulletinPage';
import EditBulletinPage from './Pages/Backstage/EditBulletinPage';
import CreateFilePage from './Pages/Backstage/CreateFilePage';
import EditFilePage from './Pages/Backstage/EditFilePage';
import ScrollToTop from './Utils/scrollToTop';

const FrontStagePage: React.FC<{ 
  page: React.ComponentType<any> 
}> = ({ page: Page }) => {
  return (
    <Flex key='FrontStagePage' position='relative' w='100%' minH='100vh' pb='154px' direction='column'>
      <Navbar />
      <Page />
      <Footer />
    </Flex>
  );
};

const BackStagePage: React.FC<{ 
  page: React.ComponentType<any> 
}> = ({ page: Page }) => {
  return (
    <Flex key='BackStagePage' position='relative' w='100%' minH='100vh' pb='96px' direction='column'>
      <BackStageNavbar />
      <Flex w='80vw' mx='10vw' my='110px'><Page /></Flex>
      <BackStageFooter />
    </Flex>
  );
};

const WithoutNavbar: React.FC<{ 
  page: React.ComponentType<any> 
}> = ({ page: Page }) => {
  return (
    <Flex key='WithoutNavbar' position='relative' w='100%' minH='100vh' pb='96px' direction='column'>
      <Page />
      <BackStageFooter />
    </Flex>
  );
};

function App() {
  return (
    <ChakraProvider theme={theme} >
      <CSSReset />
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <Redirect exact from='/' to='/upload' />
          <Route exact path='/about' render={() => <FrontStagePage page={AboutPage} />} />
          <Route exact path='/upload' render={() => <FrontStagePage page={UploadPage} />} />
          <Route exact path='/school' render={() => <FrontStagePage page={SchoolPage} />} />
          <Route exact path='/news' render={() => <FrontStagePage page={NewsPage} />} />
          <Route exact path='/FAQ' render={() => <FrontStagePage page={FAQPage} />} />
          <Route exact path='/sitemap' render={() => <FrontStagePage page={SitemapPage} />} /> 
          <Route exact path='/privacy' render={() => <FrontStagePage page={PrivacyPage} />} />  
          <Route exact path='/bs/login' render={() => <WithoutNavbar page={LoginPage} />} />
          <Route exact path='/bs/statistic' render={() => <BackStagePage page={StatisticPage} />} />
          <Route exact path='/bs/bulletin' render={() => <BackStagePage page={BulletinManagerPage} />} />
          <Route exact path='/bs/document' render={() => <BackStagePage page={FileManagerPage} />} />
          <Route exact path='/bs/createbullentin' render={() => <BackStagePage page={CreateBulletinPage} />} />
          <Route exact path='/bs/editbullentin' render={() => <BackStagePage page={EditBulletinPage} />} />
          <Route exact path='/bs/createfile' render={() => <BackStagePage page={CreateFilePage} />} />
          <Route exact path='/bs/editfile' render={() => <BackStagePage page={EditFilePage} />} />
          <Route path='/' render={() => <FrontStagePage page={Page404} />} />
        </Switch>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;