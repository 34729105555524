const Tabs = {
  variants: {
    primary: {
      tab: {
        bg: 'white',
        color: '#4F5E71',
        borderBottom: '2px solid #DAE0E5',
        _selected: {
          color: 'text.main',
          borderBottom: '2px solid #4986B0',
        }
      }
      
    },
  },
  defaultProps: {
    variant: 'primary',
  },
};

export default Tabs;
