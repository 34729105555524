import { useEffect, useState } from 'react';
import { Table, Thead, Tbody, Tr, Td, Th, TableContainer, TableCaption,
  Stack, Text, Flex, Center, Box, Link } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon, 
  ArrowDownIcon, ArrowUpIcon, InfoIcon } from '@chakra-ui/icons';

import { numAddComma } from '../../Utils/common';

interface Props {
  tableType?: 'ScholarData' | 'TeacherData',
  colNames: string[],
  rowData: any[],
  noDataText?: string,
  unitText?: string
};
export interface ScholarCertType {
  serial_no: string;
  name: string;
  URL: string;
  cnt_dd_ch: number;
  cnt_dd_en: number;
};
export interface TeacherCertType {
  name: string,
  td_cnt: number
};

const PAGE_SIZE: number = 10;

const scholarRow = (scholarData: ScholarCertType[]): JSX.Element[] => {
  let tableRow: JSX.Element[] = scholarData.length > 0 ?
    scholarData.map(item => (
      <Tr key={item.serial_no}>
        <Td p='10px 12px' color='text.main' textStyle='semibold_sm'>
          {item.serial_no}
        </Td>
        <Td p='10px 12px' color='text.main' textStyle='semibold_sm'>
          {item.name} 
          <Link ml='10px' href={item.URL} target='_blank'>
            <InfoIcon color='primary.main'/>
          </Link>
        </Td>
        <Td p='10px 12px' color='text.main' textStyle='semibold_sm' textAlign='right'>
          {numAddComma(item.cnt_dd_ch)}
        </Td>
        <Td p='10px 12px' color='text.main' textStyle='semibold_sm' textAlign='right'>
          {numAddComma(item.cnt_dd_en)}
        </Td>
      </Tr>
    )) : [];

  return tableRow;
};
const teacherRow = (scholarData: TeacherCertType[]): JSX.Element[] => {
  let tableRow: JSX.Element[] = scholarData.length > 0 ?
    scholarData.map((item, index) => (
      <Tr key={index + item.name}>
        <Td p='10px 12px' color='text.main' textStyle='semibold_sm'>
          {index + 1}
        </Td>
        <Td p='10px 12px' color='text.main' textStyle='semibold_sm'>
          {item.name}
        </Td>
        <Td p='10px 12px' color='text.main' textStyle='semibold_sm' textAlign='right'>
          {numAddComma(item.td_cnt)}
        </Td>
      </Tr>
    )) : [];

  return tableRow;
};


const SortableTable = ({ 
  tableType = 'ScholarData',
  colNames, 
  rowData, 
  noDataText = '尚未有任何資料', 
  unitText = '筆'
}: Props) => {
  const numOfPage = Math.ceil(rowData.length / PAGE_SIZE);
  const [sortColumn, setSortColumn] = useState<number>(-1);
  // sortType: increase is 0, decrease is 1
  const [sortIsAscending, setSortIsAscending] = useState<boolean[]>([false, false, false, false]);
  const [currentIdx, setCurrentIdx] = useState<number>(0);
  const [displayRange, setDisplayRange] = useState<{ begin: number, end: number }>(
    { begin: 0, end: 3 }
  );
  const produceRow = (rowData: any[]): JSX.Element[] => {
    let sortedData = rowData;
    let items: JSX.Element[] = [];
    if(tableType === 'ScholarData'){
      if(sortColumn === 0)
        sortedData = sortedData.sort((a: ScholarCertType, b: ScholarCertType) => 
          sortIsAscending[0] ? parseInt(a.serial_no) - parseInt(b.serial_no) : parseInt(b.serial_no) - parseInt(a.serial_no)
        );
      else if(sortColumn === 2)
        sortedData = sortedData.sort((a: ScholarCertType, b: ScholarCertType) => 
          sortIsAscending[2] ? a.cnt_dd_ch - b.cnt_dd_ch : b.cnt_dd_ch - a.cnt_dd_ch
        );
      else if(sortColumn === 3)
        sortedData = sortedData.sort((a: ScholarCertType, b: ScholarCertType) => 
          sortIsAscending[3] ? a.cnt_dd_en - b.cnt_dd_en : b.cnt_dd_en - a.cnt_dd_en
        );
      items = scholarRow(sortedData);
    }
    else if(tableType === 'TeacherData'){
      if(sortColumn === 2)
        sortedData = sortedData.sort((a: TeacherCertType, b: TeacherCertType) => 
          sortIsAscending[2] ? a.td_cnt - b.td_cnt : b.td_cnt - a.td_cnt
        );
      items = teacherRow(sortedData);
    }
    return items;
  };
  const VerticalLine: React.FC = () => <Flex borderRight='1px solid #35658D' />;
  const SortIcon: React.FC<{ index: number }> = ({ index }) => {
    const sortData = () => {
      if(sortColumn === index)
        setSortIsAscending(prevSortIsAscending => {
          let newSortType = [...prevSortIsAscending];
          newSortType[index] = !prevSortIsAscending[index];
          return newSortType;
        });
      setSortColumn(index);
    }
  
    return ( index === 0 || index === 2 || index === 3 ?
      <Box key={index} display='inline-block' cursor='pointer' onClick={sortData}>
        {
          sortIsAscending[index] ?
          <ArrowUpIcon boxSize='18px' color='#0172CB' /> :
          <ArrowDownIcon boxSize='18px' color='#0172CB' />
        }
      </Box> : <></>
    )
  };
  let listItems = produceRow(rowData);
  

  //Control page index functions
  const handleNext = () => {
    if (currentIdx + 1 >= numOfPage) return;
    setCurrentIdx(currentIdx + 1);
  };
  const handleLast = () => {
    if (currentIdx - 1 < 0) return;
    setCurrentIdx(currentIdx - 1);
  };

  // Update the display range when the current page index changes
  useEffect(() => {
    if (currentIdx < 2)
      setDisplayRange({ begin: 0, end: 3 });
    else if (currentIdx > numOfPage - 3)
      setDisplayRange({ begin: numOfPage - 3, end: numOfPage });
    else
      setDisplayRange({ begin: currentIdx - 1, end: currentIdx + 2 });
  }, [currentIdx, numOfPage]);
  useEffect(() => {
    if (currentIdx >= numOfPage)
      setCurrentIdx(0);
  }, [listItems, currentIdx, numOfPage]);

  return (
    <Stack spacing='24px'>
      {/* Filtered Certificate Table */}
      <TableContainer>
        <Table bg='white'>
          {listItems.length === 0 && <TableCaption my='10px' p='0'>{noDataText}</TableCaption>}
          <Thead>
            <Tr>
              {colNames.map((name: string, index) =>
                <Th key={index} p='10px 12px' textStyle='sm_bold'
                 borderColor='#A8B8C2' textAlign={ index > 1 ? 'right' : 'left' }
                >
                  {name} <SortIcon index={index} />
                </Th>
              )}
            </Tr>
          </Thead>
          <Tbody>
            {listItems.slice(PAGE_SIZE * currentIdx, PAGE_SIZE * currentIdx + PAGE_SIZE)}
          </Tbody>
        </Table>
      </TableContainer>
      {/* Index Component */}
      <Flex align='center' alignSelf='end' color='primary.main'>
        <Text mr='36px' color='grey.dark'>
          {`
          ${currentIdx * 10 + 1} - 
          ${(currentIdx + 1) * 10 > listItems.length ? listItems.length : (currentIdx + 1) * 10} / 
          ${listItems.length}
          `}
          {unitText}
        </Text>
        <Stack direction='row' spacing='0'
          border='1px solid #35658D' borderRadius='4px'
        >
          <ChevronLeftIcon w={8} h={8}
            onClick={handleLast} cursor='pointer'
          />
          {currentIdx > 1 && numOfPage > 3 && <>
            <VerticalLine />
            <Center w='32px'>...</Center>
          </>}
          <VerticalLine />
          {Array.from(Array(numOfPage).keys())
            .slice(displayRange.begin, displayRange.end)
            .map(num => 
            <Flex key={num}>
              <Center
                w='32px'
                color={currentIdx === num ? 'white' : ''}
                bgColor={currentIdx === num ? 'primary.main' : ''}
                onClick={() => setCurrentIdx(num)}
                cursor='pointer'
              >
                {num + 1}
              </Center>
              <VerticalLine />
            </Flex>)}
          {currentIdx < numOfPage - 2 && numOfPage > 3 && <>
            <Center w='32px'>...</Center>
            <VerticalLine />
          </>}
          <ChevronRightIcon w={8} h={8}
            onClick={handleNext} cursor='pointer'
          />
        </Stack>
      </Flex>
    </Stack>
  );
};

export default SortableTable;