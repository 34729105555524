import React from 'react';
import { Flex, Stack, Image, Text, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import OOPS from '../Resources/Images/Banners/oops.png';

const Page404: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Flex w='100%' pt='140px' justifyContent='center' alignItems='center' gap='24px'>
      <Image w='400px' h='400px' src={OOPS} />
      <Stack gap='27px'
        fontWeight='600' fontSize='24px' lineHeight='133%'
      >
        <Text color='primary.main'>
          {t('error404.title')[0]}<br/>
          {t('error404.title')[1]}
        </Text>
        <Text display='flex' alignItems='center' color='primary.main'>
          {t('error404.content')[0]}
          <Button variant='outlinedPrimary' margin='auto 10px' textStyle='normal_sm'
            border='1px solid #35658D' borderRadius='4px'
            onClick={() => history.goBack()}
          >
            {t('error404.button')[0]}
          </Button>
          {t('error404.content')[1]}
          <Button variant='outlinedPrimary' margin='auto 10px' textStyle='normal_sm'
            border='1px solid #35658D' borderRadius='4px'
            onClick={() => history.push('/')}
          >
            {t('error404.button')[1]}
          </Button>
          {t('error404.content')[2]}
        </Text>
      </Stack>
    </Flex>
  )
}

export default Page404