const textStyles = {
  bold_lg: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '120%',
    letterSpacing: '0.2px'
  },
  semibold_lg: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '120%',
    letterSpacing: '0.2px'
  },
  semibold_md: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '120%',
    letterSpacing: '0.16px'
  },
  normal_md: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.16px'
  },
  bold_sm: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '120%',
    letterSpacing: '0.14px'
  },
  semibold_sm: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '150%',
    letterSpacing: '0.14px'
  },
  normal_sm: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '150%',
    letterSpacing: '0.14px'
  },
  normal_xs: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '150%',
    letterSpacing: '0.14px'
  }
}

export default textStyles;