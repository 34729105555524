import React, { useState, useEffect } from 'react';
import { Flex, Text, Box, Button, useDisclosure, useToast } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';

import FileEditer from '../../Components/backstage/FileEditer';
import { CancelEditModal, ConfirmCreateModal } from './CreateBulletinPage';
import { DocumentFormType, editDocument } from '../../Utils/backStageApi';
import { checkAuth } from '../../Utils/common';

const CreateFilePage: React.FC = () => {
  const history = useHistory();
  const toast = useToast();
  const [formData, setFormData] = useState<DocumentFormType>();
  const [password, setPassword] = useState({
    text: '',
    visible: false,
    isError: false,
    errorMsg: '密碼錯誤'
  });
  const {isOpen: cancelIsOpen, onOpen: openCancel, onClose: closeCancel} = useDisclosure();
  const {isOpen: confirmIsOpen, onOpen: openConfirm, onClose: closeConfirm} = useDisclosure();

  const cancelEdit = () => {
    if(formData?.title || formData?.file)
      openCancel();
    else
      history.goBack();
  }
  const createDocument = () => {
    setPassword(prev => ({...prev, isError: false}));
    if(formData){
      editDocument(password.text, formData.title, formData.file)
      .then(response => {
        if(response && response.applied === true){
          closeConfirm();
          toast({
            title: '檔案已新增',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          history.goBack();
        }
        else{
          setPassword(prev => ({...prev, isError: true}));
          if(response?.error_msg){
            setPassword(prev => ({...prev, errorMsg: response.error_msg}));
          }
        }
      })
      .catch(error => {
        setPassword(prev => ({...prev, isError: true}));
        if(error && error.error_msg){
          setPassword(prev => ({...prev, errorMsg: error.error_msg}));
        }
      });
    }
  }

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <Flex w='100%' justify='center' alignItems='center'>
      <Flex maxW='679px' w='100%' direction='column'>
        <Flex width='fit-content' gap='10px' alignItems='center' onClick={cancelEdit} cursor='pointer'>
          <ArrowBackIcon boxSize='24px' color='teacher.light' />
          <Text color='teacher.light' textStyle='semibold_lg'>返回</Text>
        </Flex>
        <Box mt='20px'>
          <Text fontWeight='700' fontSize='30px' lineHeight='133%' color='teacher.primary'>
            新增文件下載檔案
          </Text>
        </Box>
        <Box mt='75px'>
          <FileEditer setFormData={setFormData}/>
        </Box>
        <Flex mt='60px' justify='center' gap='30px'>
          <Button variant='outlinedCritical' p='8px 12px' 
            border='1px solid' onClick={cancelEdit}
          >
            取消
          </Button>
          <Button p='8px 12px' onClick={openConfirm}
            disabled={!formData?.title || !formData?.file}
          >
            新增
          </Button>
        </Flex>
      </Flex>
      <CancelEditModal
        isOpen={cancelIsOpen}
        onClose={closeCancel}
      />
      <ConfirmCreateModal
        isOpen={confirmIsOpen}
        onClose={closeConfirm}
        password={password}
        setPassword={setPassword}
        createFunc={() => createDocument()}
      />
    </Flex>
  );
};

export default CreateFilePage;
