import { useState } from 'react';
import { Flex, Image, Text,
  Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';

import banner from '../Resources/Images/Banners/news_banner.png';
import Bulletin from '../Components/news/Bulletin';
import Document from '../Components/news/Document';
import CenterGuideBrick from '../Components/Common/CenterGuideBrick';

const NewsPage: React.FC = () => {
  const currentHash = window.location.hash;
  const [activeTab, setActiveTab] = useState(currentHash === '#document' ? 1 : 0);

  const handleTabChange = (index: number) => {
    setActiveTab(index);
  };

  return (
    <Flex w='100%' minH='84vh' direction='column' align='center'>
      <Image w='100%' maxH='219px' objectFit='cover' src={banner} alt='最新消息'/>
      <Flex w='85%' h='100%' py='32px' direction='column'>
        <CenterGuideBrick/>
        <Text py='30px' color='text.main' textStyle='bold_lg'>最新消息</Text>
        <Tabs variant='primary' index={activeTab} onChange={handleTabChange}>
          <TabList>
            <Tab textStyle='semibold_md'>計畫公告</Tab>
            <Tab textStyle='semibold_md'>文件下載</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Bulletin/>
            </TabPanel>
            <TabPanel>
              <Document/>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </Flex>
  )
};

export default NewsPage;