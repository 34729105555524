import React, { useState, useEffect } from 'react';
import { Flex, Text, Box, Button, useDisclosure, useToast,
  Input, InputGroup, InputRightElement, InputLeftElement,
  FormControl, FormErrorMessage, Modal, ModalOverlay, ModalContent, 
  ModalHeader, ModalFooter, ModalBody } from '@chakra-ui/react';
  import { ViewIcon, ViewOffIcon, ArrowBackIcon } from '@chakra-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';

import FileEditer from '../../Components/backstage/FileEditer';
import { CancelEditModal } from './CreateBulletinPage';
import { DocumentFormType, editDocument, deleteDocument } from '../../Utils/backStageApi';
import { checkAuth } from '../../Utils/common';

const EditFilePage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const toast = useToast();
  const [lastData, setLastData] = useState<DocumentFormType>();
  const [formData, setFormData] = useState<DocumentFormType>();
  const [password, setPassword] = useState({
    text: '',
    visible: false,
    isError: false,
    errorMsg: '密碼錯誤'
  });
  const [modalMode, setModalMode] = useState<string>('update');
  const {isOpen: cancelIsOpen, onOpen: openCancel, onClose: closeCancel} = useDisclosure();
  const {isOpen: confirmIsOpen, onOpen: openConfirm, onClose: closeConfirm} = useDisclosure();

  const cancelEdit = () => {
    if(_.isEqual(lastData?.title, formData?.title))
      history.goBack();
    else
      openCancel();
  }
  const openPasswordModal = (mode: string) => {
    setModalMode(mode);
    openConfirm();
  }
  const updateBulletin = () => {
    setPassword(prev => ({...prev, isError: false}));
    if(formData){
      editDocument(password.text, formData.title, formData.file, formData.did)
      .then(response => {
        if(response && response.applied === true){
          closeConfirm();
          toast({
            title: '檔案已更新',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          history.goBack();
        }
        else{
          setPassword(prev => ({...prev, isError: true}));
          if(response?.error_msg){
            setPassword(prev => ({...prev, errorMsg: response.error_msg}));
          }
        }
      })
      .catch(error => {
        setPassword(prev => ({...prev, isError: true}));
        if(error && error.error_msg){
          setPassword(prev => ({...prev, errorMsg: error.error_msg}));
        }
      });
    }
  }
  const removeBulletin = () => {
    setPassword(prev => ({...prev, isError: false}));
    if(formData){
      deleteDocument(password.text, formData.did)
      .then(response => {
        if(response && response.applied === true){
          closeConfirm();
          toast({
            title: '檔案已移除',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          history.goBack();
        }
        else{
          setPassword(prev => ({...prev, isError: true}));
          if(response?.error_msg){
            setPassword(prev => ({...prev, errorMsg: response.error_msg}));
          }
        }
      })
      .catch(error => {
        setPassword(prev => ({...prev, isError: true}));
        if(error && error.error_msg){
          setPassword(prev => ({...prev, errorMsg: error.error_msg}));
        }
      });
    }
  }

  useEffect(() => {
    checkAuth();
  }, []);
  
  useEffect(() => {
    const state = location.state as { did: number, title: string, link: string };
    const url = (window as any).env.MAIN_HOST + state.link;
    let filename = new URL(url).pathname.split('/').pop() || 'document_file';

    fetch(url)
    .then(response => response.blob().then(blob => {
        return {
          file: new File([blob], decodeURIComponent(filename), { type: blob.type })
        };
      })
    )
    .then(({ file }) => {
      setLastData({
        did: state.did,
        title: state.title,
        file: file
      });
    })
    .catch(error => {
      console.error(error);
      setLastData({
        did: state.did,
        title: state.title,
        file: null
      })
    });
  }, [location.state]);

  return (
    <Flex w='100%' justify='center' alignItems='center'>
      <Flex maxW='679px' w='100%' direction='column'>
        <Flex width='fit-content' gap='10px' alignItems='center' onClick={cancelEdit} cursor='pointer'>
          <ArrowBackIcon boxSize='24px' color='teacher.light' />
          <Text color='teacher.light' textStyle='semibold_lg'>返回</Text>
        </Flex>
        <Box mt='20px'>
          <Text fontWeight='700' fontSize='30px' lineHeight='133%' color='teacher.primary'>
            編輯文件下載檔案
          </Text>
        </Box>
        <Box mt='75px'>
          <FileEditer initData={lastData} setFormData={setFormData}/>
        </Box>
        <Flex mt='60px' justify='center' gap='30px'>
          <Button variant='outlinedCritical' p='8px 12px' 
            border='1px solid' onClick={() => openPasswordModal('remove')}
          >
            移除
          </Button>
          <Button p='8px 12px' onClick={() => openPasswordModal('update')}
            disabled={!formData?.title || !formData?.file}
          >
            更新
          </Button>
        </Flex>
      </Flex>
      <CancelEditModal
        isOpen={cancelIsOpen}
        onClose={closeCancel}
      />
      <Modal isOpen={confirmIsOpen} onClose={closeConfirm} size='xl' isCentered>
        <ModalOverlay />
        <ModalContent maxW='540px'>
          <ModalHeader p='24px 24px 16px 24px'>
            <Text textStyle='semibold_lg'
             color={modalMode === 'update' ? 'text.main' : 'critical.300'}
            >
              請輸入密碼進行{modalMode === 'update' ? '更新' : '移除'}
            </Text>
          </ModalHeader>
          <ModalBody p='0 24px 16px 24px' color='grey.700'>
            <FormControl w='80%' isInvalid={password.isError}>
              <InputGroup>
                <InputLeftElement
                  color='text.main'
                  children={<Text textStyle='semibold_sm'>密碼</Text>}
                />
                <Input
                  type={password.visible ? 'text' : 'password'}
                  name='password'
                  placeholder='請輸入密碼'
                  onChange={e => setPassword(prev => ({...prev, text: e.target.value}))}
                  value={password.text}
                />
                {password && (
                  <InputRightElement
                    color='primary.main'
                    cursor={'pointer'}
                    onClick={() => setPassword(prev => ({...prev, visible: !prev.visible}))}
                    children={password.visible ? <ViewOffIcon color='primary.main' /> : <ViewIcon color='primary.main' />}
                  />
                )}
              </InputGroup>
              <FormErrorMessage>{password.errorMsg}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter p='0 24px 24px 24px' gap='8px'>
            <Button variant='secondary' onClick={closeConfirm}>
              返回
            </Button>
            <Button variant={modalMode === 'update' ? 'primary' : 'critical'}
              onClick={() => modalMode === 'update' ? updateBulletin() : removeBulletin()}
              disabled={!password.text}
            >
              {modalMode === 'update' ? '更新' : '移除'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default EditFilePage;
