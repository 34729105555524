import axios, { AxiosResponse } from 'axios';

const MAIN_HOST = (window as any).env.MAIN_HOST;

export type StatisticType = {
  issued_cnt_dd_ch: number,
  issued_cnt_dd_en: number,
  issued_cnt_td: number,
  verify_cnt_dd: number,
  verify_cnt_td: number,
  visitor_cnt: number
}

export type BulletinType = {
  bid: number,
  date: string,
  title: string,
  tag: string,
  text: string
}

export type BulletinListType = {
  bid: number,
  date: string,
  title: string,
  tag: string,
  text: string
}[]

export type DocumentType = {
  did: number,
  date: string,
  title: string,
  link: string
}

export type DocumentFormType = {
  did: number;
  title: string;
  file: File | null;
}

interface ApiResponse {
  applied: boolean;
  error_msg: string;
}

interface StatResponse extends ApiResponse {
  issued_cnt_dd_ch: number;
  issued_cnt_dd_en: number;
  issued_cnt_td: number;
  verify_cnt_dd: number;
  verify_cnt_td: number;
  visitor_cnt: number;
}

interface CertResponse extends ApiResponse {
  count: number;
  list: any[];
}

interface BulletinResponse extends ApiResponse {
  bid?: number;
  date?: string;
}

interface DocumentResponse extends ApiResponse {
  did?: number;
  date?: string;
  link?: string;
}

/**
 * Logout from the backend.
 * This function makes a GET request to the /accounts/logout/ endpoint.
 */
export const logout = async(): Promise<ApiResponse> => {
  const url = `${MAIN_HOST}/accounts/logout/`;
  return await axios.get(url);
}

/**
 * Check the login status.
 * This function makes a GET request to the /backstage/authcheck/ endpoint.
 */
export const authCheck = async(): Promise<ApiResponse | undefined> => {
  const url = `${MAIN_HOST}/backstage/authcheck/`;
  try {
    const response: AxiosResponse = await axios.get(url);
    
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Fetch statistics data.
 * This function makes a GET request to the /backstage/stat/ endpoint.
 * @param {number} op - operation mode (1 or 2)
 */
export const getSumStatistic = 
async(op: number = 1)
: Promise<StatResponse | undefined> => {
  const url = `${MAIN_HOST}/backstage/stat/`;
  const params = { op };

  try {
    const response: AxiosResponse = await axios.get(url, { params });
    
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Fetch statistics data.
 * This function makes a GET request to the /backstage/stat/ endpoint.
 * @param {number} op - operation mode (1 or 2)
 * @param {number} [cat] - category (required when op is 2), specify the type (1: degree certificate 2: teacher certificate)
 */
export const getCertStatistic = 
async(cat: number, op: number = 2)
: Promise<CertResponse | undefined> => {
  const url = `${MAIN_HOST}/backstage/stat/`;
  const params = { op, cat };
  
  try {
    const response: AxiosResponse = await axios.get(url, { params });
    
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Manage the bulletin (create/update).
 * This function makes a POST request to the /backstage/bulletin/ endpoint.
 * @param {string} passwd - user password
 * @param {string} tag - bulletin tag for adding/updating
 * @param {string} title - bulletin title for adding/updating
 * @param {string} text - bulletin text for adding/updating
 * @param {number} [bid] - bulletin id to update/delete (omit this for adding a new bulletin)
 * 
 * 1. Create mode: The bid parameter is not passed, and the other three parameters are passed, 
 * which are new announcements, and the return value will have the bid and date of the new announcement.
 * 2. Update mode: The bid parameter and the other three parameters are passed. 
 * In order to update the announcement, the returned value will have an updated date
 */
export const editBulletin = 
async(passwd: string, tag: string, title: string, text: string, bid?: number)
: Promise<BulletinResponse | undefined> => {
  const url = `${MAIN_HOST}/backstage/bulletin/`;
  const formData = new FormData();

  formData.append('passwd', passwd);
  formData.append('tag', tag);
  formData.append('title', title);
  formData.append('text', text);
  // Append 'bid' only if it is provided
  if (bid) {
    formData.append('bid', bid.toString());
  }
  
  try {
    const response: AxiosResponse = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Delete the bulletin.
 * This function makes a POST request to the /backstage/bulletin/ endpoint.
 * @param {string} passwd - user password
 * @param {number} bid - bulletin id to update/delete (omit this for adding a new bulletin)
 * 
 * Only the bid parameter is passed to delete the announcement.
 */
export const deleteBulletin = 
async(passwd: string, bid: number)
: Promise<BulletinResponse | undefined> => {
  const url = `${MAIN_HOST}/backstage/bulletin/`;
  const formData = new FormData();
  
  formData.append('passwd', passwd);
  formData.append('bid', bid.toString());

  try {
    const response: AxiosResponse = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Manage the documents (create/update).
 * This function makes a POST request to the /backstage/document/ endpoint.
 * @param {string} passwd - user password
 * @param {string} title - document title for adding/updating
 * @param {File} file - document file for adding/updating
 * @param {number} [did] - document id to update (omit this for adding a new document)
 * 
 * 1. Create mode: The did parameter is not passed, but the other two parameters are passed, 
 * which are added files, and the returned value will have the did, date and link of the newly added file.
 * 2. Update mode: There is the did parameter and the other two parameters. 
 * In order to update the file, the returned value will have the updated date and link
 */
export const editDocument = 
async(passwd: string, title: string, file: File | null, did?: number)
: Promise<DocumentResponse | undefined> => {
  const url = `${MAIN_HOST}/backstage/document/`;
  const formData = new FormData();
  formData.append('passwd', passwd);
  formData.append('title', title);
  if (file)
    formData.append('file', file);
  if (did) 
    formData.append('did', did.toString());
  
  try {
    const response: AxiosResponse = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Delete the documents.
 * This function makes a POST request to the /backstage/document/ endpoint.
 * @param {string} passwd - user password
 * @param {number} did - document id
 * 
 * Only the did parameter is passed to delete the file
 */
export const deleteDocument = 
async(passwd: string, did: number)
: Promise<DocumentResponse | undefined> => {
  const url = `${MAIN_HOST}/backstage/document/`;
  const formData = new FormData();
  formData.append('passwd', passwd);
  if (did) {
    formData.append('did', did.toString());
  }
  
  try {
    const response: AxiosResponse = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export const downloadStatistic = async() => {
  const url = `${MAIN_HOST}/backstage/csvstat/`;
  return await axios.get(url);
}