import React, { useState } from 'react';
import { Flex, Text, Button, Box,
  Input, InputGroup, InputRightElement, InputLeftElement,
  FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { ViewIcon, ViewOffIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';

const LoginPage: React.FC = () => {
  const history = useHistory();
  const [showPasswd, setShowPasswd] = useState(false);
  
  const validate = (values: any) => {
    const errors: any = {};
    if (!values.account) {
      errors.account = '請輸入帳號';
    }
    if (!values.password) {
      errors.password = '請輸入密碼';
    }

    return errors;
  };
  const onSubmit = (values: any) => {
    if (values.account !== 'admin' || values.password !== 'password') {
      formik.setFieldError('account', '帳號或密碼錯誤');
      formik.setFieldError('password', '帳號或密碼錯誤');
      return;
    }
    history.push('/bs/statistic');
  };
  const formik = useFormik({
    initialValues: { account: '', password: '' },
    validate,
    onSubmit,
  });
  return (
    <Flex w='100vw' h='calc(100vh - 96px)' justify='center' alignItems='center'>
      <Flex w='304px' alignItems='center' direction='column'>
        <Box>
          <Text fontWeight='700' fontSize='24px' lineHeight='133%'>教育部電子證書驗證系統</Text>
        </Box>
        <Box mt='19px'>
          <Text fontWeight='700' fontSize='30px' lineHeight='120%'>後台管理平台</Text>
        </Box>
        <Box mt='80px'>
          <form onSubmit={formik.handleSubmit}>
            <FormControl isInvalid={!!formik.errors.account}>
              <InputGroup>
                <InputLeftElement
                  color='text.main'
                  children={<Text textStyle='semibold_sm'>帳號</Text>}
                />
                <Input
                  type='account'
                  name='account'
                  placeholder='請輸入帳號'
                  onChange={formik.handleChange}
                  value={formik.values.account}
                />
                {formik.values.account && (
                  <InputRightElement
                    color='primary.main'
                    cursor={'pointer'}
                    onClick={() => formik.setFieldValue('account', '')}
                    children={<SmallCloseIcon color='primary.main' />}
                  />
                )}
              </InputGroup>
              <FormErrorMessage>{formik.errors.account}</FormErrorMessage>
            </FormControl>
            <FormControl mt='26px' isInvalid={!!formik.errors.password}>
              <InputGroup>
                <InputLeftElement
                  color='text.main'
                  children={<Text textStyle='semibold_sm'>密碼</Text>}
                />
                <Input
                  type={showPasswd ? 'text' : 'password'}
                  name='password'
                  placeholder='請輸入密碼'
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                {formik.values.password && (
                  <InputRightElement
                    color='primary.main'
                    cursor={'pointer'}
                    onClick={() => setShowPasswd(!showPasswd)}
                    children={showPasswd ? <ViewOffIcon color='primary.main' /> : <ViewIcon color='primary.main' />}
                  />
                )}
              </InputGroup>
              <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
            </FormControl>
            <Button w='100%' mt='50px' type='submit'
              disabled={!formik.values.account || !formik.values.password}
            >
              登入
            </Button>
          </form>
        </Box>
      </Flex>
    </Flex>
  );
};

export default LoginPage;
