import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import zh from './zh-TW.json';
import en from './en-US.json';

const dictionary = {
  en: { translation: en },
  zh: { translation: zh },
};

i18n.use(initReactI18next).init({
  resources: dictionary,
  lng: navigator.language.includes('zh') ? 'zh' : 'en',
  returnObjects: true,
  interpolation: {
    escapeValue: false,
  },
});

export default dictionary;
