import React, { useState, useEffect } from 'react';
import { Button, Flex, Stack, Text, Tr, Td,
  Input, InputLeftElement, InputGroup } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';

import PaginationTable from '../../Components/Common/PaginationTable';
import { getDocument } from '../../Utils/frontStageApi';
import { DocumentType } from '../../Utils/backStageApi';
import { checkAuth } from '../../Utils/common';

const FileManagerPage: React.FC = () => {
  const history = useHistory();
  const [documents, setDocuments] = useState<DocumentType[]>();
  const [textFilter, setTextFilter] = useState<string>('');
  const goCreatePage = () => {
    history.push('/bs/createfile');
  }
  const goEditPage = (item: DocumentType) => {
    history.push('/bs/editfile', {
      did: item.did,
      title: item.title,
      link: item.link
    });
  }
  let rowData = documents ? documents
    .filter(item => item.title.includes(textFilter))
    .map(item => (
      <Tr key={item.did}>
        <Td p='10px 12px' color='text.main' textStyle='semibold_sm'>
          {item.date}
        </Td>
        <Td p='10px 12px' color='text.main' textStyle='semibold_sm'>
          <Flex justify='space-between' alignItems='center'>
            <Text>{item.title}</Text>
            <Button p='8px 12px' bg='white' color='primary.main'
              border='1px solid' borderColor='primary.main'
              onClick={() => goEditPage(item)}
            >
              編輯
            </Button>
          </Flex>
        </Td>
      </Tr>
    )) : [];

  useEffect(() => {
    checkAuth();
    getDocument(0)
    .then((data: any) => {
      if (data && data.list) {
        setDocuments(data.list);
      }
    });
  }, []);

  return (
    <Stack w='100%' spacing='72px'>
      {/* Title */}
      <Flex justify='space-between' alignItems='center'>
        <Text color='#2A7157' fontWeight='700' fontSize='30px' lineHeight='133%'>文件下載公告管理</Text>
        <Button p='8px 12px' onClick={goCreatePage}>新增</Button>
      </Flex>
      {/* Bulletin Information */}
      <Stack mt='70px' spacing='35px'>
        {/* Text Filter */}
        <InputGroup w='min(475px, 100%)'>
          <InputLeftElement children={<SearchIcon color='primary.main' />} />
          <Input
            placeholder='請輸入標題進行搜尋'
            value={textFilter}
            onChange={e => setTextFilter(e.target.value)}
          />
        </InputGroup>
        {/* Announcement List */}
        <PaginationTable
          colNames={['公告日期', '公告檔案']}
          listItems={rowData}
        />
      </Stack>
    </Stack>
  );
};

export default FileManagerPage;

