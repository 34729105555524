import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { useTranslation } from 'react-i18next';
import './Burger.css';

export default (props: any) => {
  const { t, i18n } = useTranslation();
  return (
    <Menu right width={'200px'}>
      <a className="menu-item" href="/about">
        {i18n.language === 'zh' ? '關於' : 'Home'}
      </a>
      <br />
      <a className="menu-item" href="/upload">
        {i18n.language === 'zh' ? '證書驗證' : 'Certificate Verification'}
      </a>
      <br />
      {i18n.language === 'zh' &&
      <a className="menu-item" href="/news">
        最新消息
      </a>
      }
      {i18n.language === 'zh' && <br />}
      <a className="menu-item" href="/FAQ">
        {i18n.language === 'zh' ? '常見問題' : 'FAQs'}
      </a>
      <br />
      <a className="menu-item" href="/school">
        {i18n.language === 'zh' ? '發證單位' : 'Issuance Units'}
      </a>
    </Menu>
  );
};