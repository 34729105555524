const Button = {
  baseStyle: {
    fontWeight: 'normal',
    _disabled: {
      bg: 'additional.primary.dark.disabled',
    },
    _hover: {
      bg: 'primary.dark',
    },
  },
  variants: {
    primary: {
      bg: 'primary.main',
      color: 'white',
      _hover: {
        bg: 'primary.dark',
      },
      _active: {
        bg: 'primary.darker',
      },
      _disabled: {
        bg: 'additional.primary.dark.disabled',
      }
    },
    secondary:{
      bg: 'additional.primary_greytone.dark.default',
      color: 'text.main',
      _hover: {
        bg: 'additional.primary_greytone.dark.hover'
      },
      _active: {
        bg: 'additional.primary_greytone.dark.active'
      },
      _disabled: {
        bg: 'additional.primary_greytone.dark.disabled',
      }
    },
    critical: {
      bg: 'critical.300',
      color: 'white',
      _hover: {
        bg: 'critical.400'
      },
      _active: {
        bg: 'critical.500'
      },
      _disabled: {
        bg: 'critical.200',
      }
    },
    outlinedPrimary: {
      bg: 'transparent',
      color: 'primary.main',
      _hover: {
        bg: '#E5F1F6',
      },
      _active: {
        bg: '#BFDCE9',
      },
      _disabled: {
        color: '#7BB1CD',
        borderColor: '#7BB1CD',
      }
    },
    outlinedSecondary: {
      bg: 'transparent',
      color: 'text.main',
      _hover: {
        color: 'white',
      },
      _active: {
        bg: '#DAE0E5',
      },
      _disabled: {
        color: '#C1CCD1',
        borderColor: '#C1CCD1',
      }
    },
    outlinedCritical: {
      bg: 'transparent',
      color: 'critical.300',
      borderColor: 'critical.300',
      _hover: {
        bg: 'critical.50',
        color: 'critical.500',
        borderColor: 'critical.500',
      },
      _active: {
        bg: 'critical.100',
        color: 'critical.500',
        borderColor: 'critical.500',
      },
      _disabled: {
        bg: 'transparent',
        color: 'critical.200',
        borderColor: 'critical.200',
      }
    },
  },
  defaultProps: {
    variant: 'primary',
  },
};

export default Button;
