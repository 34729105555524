import React, { useState, useEffect } from 'react';
import { Flex, Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { getSummary } from '../../Utils/frontStageApi';
import { numAddComma } from '../../Utils/common';
import useMedia from '../../Utils/useMedia';

type StatisticType = {
  digitalCertificates: string;
  issuanceUnits: string;
  totalIssuances: string;
  totalVerifications: string;
};

type CardType = {
  number: string;
  text: string;
  width: string;
  mt: string;
  mb: string;
}

const Statistics: React.FC = () => {
  const { t } = useTranslation();
  const [statistic, setStatistic] = useState<StatisticType>();
  const { isMobile, isDesktop, isTablet } = useMedia();
  const DataCard: React.FC<CardType> = ({number, text, width, mt, mb}) => 
    <Stack w={width} mt={mt} mb={mb} justify='center'>
      <Text textAlign='center' color='primary.main'
        fontWeight='700' fontSize='60px' lineHeight='100%'
      >
        {number}
      </Text>
      <Text mt='17.5px' textAlign='center' textStyle='bold_lg' color='#A8B8C2'>
        {text}
      </Text>
    </Stack>;

  useEffect(() => {
    getSummary().then((data: any) =>
      setStatistic({
        digitalCertificates: data.cat_num,
        issuanceUnits: data.issuer_num,
        totalIssuances: numAddComma(data.issued_cnt),
        totalVerifications: numAddComma(data.verify_cnt)
      })
    );
  }, []);

  const statCardHorizontal = () => {
    if (statistic) return (
        <Flex w='100%' h='210px' align='center' 
          bg='#F3F5F6' borderRadius='10px'
        >
          <DataCard mt='0px' mb='0px' number={statistic['digitalCertificates']} text={t('statistics.digitalCertificates')} width='25%' />
          <Flex w='1px' h='75%' bg='#DAE0E5' />
          <DataCard mt='0px' mb='0px' number={statistic['issuanceUnits']} text={t('statistics.issuanceUnits')} width='25%' />
          <Flex w='1px' h='75%' bg='#DAE0E5' />
          <DataCard mt='0px' mb='0px' number={statistic['totalIssuances']} text={t('statistics.totalIssuances')} width='25%' />
          <Flex w='1px' h='75%' bg='#DAE0E5' />
          <DataCard mt='0px' mb='0px' number={statistic['totalVerifications']} text={t('statistics.totalVerifications')} width='25%' />
        </Flex>
    );
  }

  const statCardVertical = () => {
    if (statistic) return (
        <Flex w='100%' direction='column'
          bg='#F3F5F6' borderRadius='10px'
        >
          <DataCard mt='20px' mb='60px' number={statistic['digitalCertificates']} text={t('statistics.digitalCertificates')} width='100%' />
          <DataCard mt='0px'  mb='60px' number={statistic['issuanceUnits']} text={t('statistics.issuanceUnits')} width='100%' />
          <DataCard mt='0px'  mb='60px' number={statistic['totalIssuances']} text={t('statistics.totalIssuances')} width='100%' />
          <DataCard mt='0px'  mb='20px' number={statistic['totalVerifications']} text={t('statistics.totalVerifications')} width='100%' />
        </Flex>
    );
  }

  return (
    <Stack mt='50px' mb='80px' spacing='30px'>
      <Flex w='100%' justify='start'>
        <Text textStyle='semibold_lg' color='secondary.main'>
          {t('upload.latestStatistics')}
        </Text>
      </Flex>
      {statistic && isDesktop  && statCardHorizontal()}
      {statistic && !isDesktop && statCardVertical()}
    </Stack>
  )
}

export default Statistics;
