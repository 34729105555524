import React, { useState, useEffect } from 'react';
import { Button, Flex, Stack, Text, useDisclosure,
  Tabs, TabList, Tab, TabPanel, TabPanels,
  Input, InputLeftElement, InputGroup } from '@chakra-ui/react';
import { DownloadIcon, SearchIcon } from '@chakra-ui/icons';

import SortableTable, { ScholarCertType, TeacherCertType } from '../../Components/statistic/SortableTable';
import { SimpleModal } from '../../Components/Common/Modal';
import { StatisticType, getSumStatistic, getCertStatistic, downloadStatistic } from '../../Utils/backStageApi';
import { checkAuth, numAddComma } from '../../Utils/common';

interface StatisticCardProps {
  subLabel?: string;
  label: string;
  color?: string;
  bgColor?: string;
  value: string;
}

const StatisticCard: React.FC<StatisticCardProps> = ({ subLabel, label, color = 'black', bgColor = 'grey.300', value }) => (
  <Stack w='30%' p='16px' bg='#FAFCFE' border='1px solid' borderColor='grey.light' borderRadius='10px'>
    <Flex gap='4px' alignItems='center'>
      {subLabel && <Flex p='1px 9px' justify='center' alignItems='center' borderRadius='32px' bg={bgColor}>
        <Text color={color} textStyle='semibold_md'>{subLabel}</Text>
      </Flex>}
      <Text fontSize='18px' color='black'>{label}</Text>
    </Flex>
    <Flex justify='end'>
      <Text fontWeight='700' fontSize='40px' lineHeight='100%'>{value}</Text>
    </Flex>
  </Stack>
);

const StatisticPage: React.FC = () => {
  const [textFilter, setTextFilter] = useState<string>('');
  const [statistic, setStatistic] = useState<StatisticType>();
  const [scholarCert, setScholarCert] = useState<ScholarCertType[]>();
  const [teacherCert, setTeacherCert] = useState<TeacherCertType[]>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const downloadCsv = () => {
    downloadStatistic()
    .then(response => {
      if(response.headers['content-type'] === 'text/csv') {
        const blob = new Blob(['\uFEFF' + response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.href = url;
        a.download = 'certificate_statistic.csv'
        a.click();
        window.URL.revokeObjectURL(url);
      }
      else {
        onOpen();
      }
    });
  }

  useEffect(() => {
    checkAuth();
    getSumStatistic()
    .then((data: any) => {
      const { applied, error_msg, ...statistics } = data;
      setStatistic(statistics);
    });
    getCertStatistic(1)
    .then((data: any) => {
      setScholarCert(data.list);
    });
    getCertStatistic(2)
    .then((data: any) => {
      setTeacherCert(data.list);
    });
  }, []);

  return (
    <Stack w='100%' spacing='72px'>
      <Flex justify='space-between' alignItems='center'>
        <Text color='primary.main' fontWeight='700' fontSize='30px' lineHeight='133%'>統計數據</Text>
        <Button p='8px 16px' onClick={downloadCsv}>
          <DownloadIcon boxSize='16px' mr='8px'/>報表下載
        </Button>
      </Flex>
      {/* Statistic Information */}
      {statistic &&
        <Stack spacing='40px'>
          <Flex justify='space-between'>
            <StatisticCard subLabel='總發證數' label='中文學位證書'
              value={numAddComma(statistic.issued_cnt_dd_ch)}
              color='#2A7157' bgColor='#B8D9CD'
            />
            <StatisticCard subLabel='總發證數' label='英文學位證書'
              value={numAddComma(statistic.issued_cnt_dd_en)}
              color='#2A7157' bgColor='#B8D9CD'
            />
            <StatisticCard subLabel='總發證數' label='教師證書'
              value={numAddComma(statistic.issued_cnt_td)}
              color='#2A7157' bgColor='#B8D9CD'
            />
          </Flex>
          <Flex justify='space-between'>
            <StatisticCard subLabel='總驗證數' label='學位證書'
              value={numAddComma(statistic.verify_cnt_dd)}
              color='primary.main' bgColor='additional.primary.light.active'
            />
            <StatisticCard subLabel='總驗證數' label='教師證書'
              value={numAddComma(statistic.verify_cnt_td)}
              color='primary.main' bgColor='additional.primary.light.active'
            />
            <StatisticCard label='驗證網站瀏覽人次'
              value={numAddComma(statistic.visitor_cnt)}
            />
          </Flex>
        </Stack>
      }
      {/* Certificate Information */}
      <Tabs w='100%'>
        <Flex justify='space-between'>
          <TabList>
            <Tab fontWeight='600' fontFamily='16px' lineHeight='120%'>學位證書</Tab>
            <Tab fontWeight='600' fontFamily='16px' lineHeight='120%'>教師證書</Tab>
          </TabList>
          <InputGroup w='300px' >
            <InputLeftElement children={<SearchIcon color='primary.main' />} />
            <Input
              placeholder='發證單位'
              value={textFilter}
              onChange={e => setTextFilter(e.target.value)}
            />
          </InputGroup>
        </Flex>
        <TabPanels mt='32px'>
          <TabPanel>
            <SortableTable
              tableType='ScholarData'
              colNames={['序號', '發證單位', '中文證書總發證數', '英文證書總發證數']}
              rowData={scholarCert ? scholarCert.filter(item => item.name.includes(textFilter)) : []}
            />
          </TabPanel>
          <TabPanel>
            <SortableTable
              tableType='TeacherData'
              colNames={['序號', '發證單位', '教師證書總發證數']}
              rowData={teacherCert ? teacherCert.filter(item => item.name.includes(textFilter)) : []}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <SimpleModal
        isOpen={isOpen}
        onClose={onClose}
        header={<Text textStyle='semibold_lg'>下載失敗！詳情請聯絡開發人員！</Text>}
      />
    </Stack>
  );
};

export default StatisticPage;

