import React, { useState, useEffect } from 'react';
import { Flex, Stack, Box, Text, useDisclosure,
  Tr, Td, Modal, ModalOverlay, ModalContent, 
  ModalHeader, ModalBody } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useLocation } from 'react-router-dom';

import { Hashtag } from '../upload/BulletinBoard';
import PaginationTable from '../Common/PaginationTable';
import { getBulletinList, getBulletin } from '../../Utils/frontStageApi';
import { BulletinType, BulletinListType } from '../../Utils/backStageApi';

const Bulletin: React.FC = () => {
  const location = useLocation();
  const [announcements, setAnnouncements] = useState<BulletinListType>();
  const [modalInfo, setModalInfo] = useState<BulletinType>();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const viewAnnouncement = (bid: number) => {
    getBulletin(bid)
    .then(data => {
      if(data && data.content){
        setModalInfo(data.content);
      }
      onOpen();
    })
  }
  let rowData = announcements ?
   announcements.map(item => (
    <Tr key={item.bid} cursor='pointer'
      onClick={() => viewAnnouncement(item.bid)}
    >
      <Td p='10px 12px' color='text.main' textStyle='semibold_sm'>
        {item.date}
      </Td>
      <Td p='10px 12px' color='text.main' textStyle='semibold_sm'>
        {item.title}
      </Td>
      <Td p='6px 10px' color='text.main' textStyle='bold_sm' borderBottom='none'>
        <Hashtag text={item.tag}/>
      </Td>
    </Tr>
    )) : [];

  useEffect(() => {
    const state = location.state as { bid: number };
    const bid = state ? state.bid : null;

    getBulletinList(0)
    .then((data: any) => {
      if (data && data.list) {
        setAnnouncements(data.list);
      }
    });
    if(bid){
      viewAnnouncement(bid);
    }
  }, [location]);

  return (
    <React.Fragment>
      <Stack w='100%' p='58px 80px' my='40px' spacing='24px' bg='#E5F1F6' borderRadius='30px'>
        <Flex justify='start' alignItems='center'>
          <Flex gap='5px' alignItems='center'>
            <Box w='10px' h='15px' bg='primary.main' borderRadius='5px' />
            <Text textStyle='semibold_lg' color='primary.main'>計畫公告</Text>
          </Flex>
        </Flex>
        <PaginationTable
          colNames={['公告日期', '公告主旨', '公告分類']}
          listItems={rowData}
        />
      </Stack>
      {/* Display Announcement Modal */}
      {modalInfo && <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent maxW='790px' p='30px'>
          <ModalHeader>
            <Flex justify='space-between' alignItems='start'>
              <Flex justify='center' alignItems='center'
                boxSize='56px' borderRadius='10px' textStyle='bold_sm'
                bg='#E5F1F6' color='primary.main'
              >
                NEWS
              </Flex>
              <CloseIcon w='24px' cursor='pointer' onClick={onClose}/>
            </Flex>
          </ModalHeader>
          <ModalBody>
            <Stack spacing='12px'>
              <Text color='grey.700' textStyle='normal_md'>{modalInfo.date}</Text>
              <Text color='text.main' fontWeight='600' fontSize='24px' lineHeight='133%'>
                {modalInfo.title}
              </Text>
              {modalInfo.text.split('\n').map((paragraph, index) => (
                <Text key={index} color='grey.700' fontSize='18px'>
                  {paragraph}<br />
                </Text>
              ))}
            </Stack>
            <Box mt='40px'>
              <Hashtag text={modalInfo.tag}/>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>}
    </React.Fragment>
  )
};

export default Bulletin;