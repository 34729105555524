import { authCheck } from './backStageApi';

enum Estatus {
  upload = 0,
  status,
  result
};

/**
 * Reads the provided file and checks its format.
 *
 * @param file - The file to be read.
 * @param setWrongFormat - Callback function to set the wrong format flag.
 * @param setStatus - Callback function to set the status.
 * @param setUploadFile - Callback function to set the uploaded file.
 */
export const readfile = (
  file: File,
  setWrongFormat: (wrong: boolean) => void,
  setStatus: (status: Estatus) => void,
  setUploadFile: (file: File) => void
) => {
  const doubleExtension = file.name.split('.');
  const allowedExtensions = /(\.pdf)$/i;

  setWrongFormat(false);
  if (file && allowedExtensions.exec(file.name) && doubleExtension.length === 2) {
    setUploadFile(file);
    setStatus(Estatus.status);
  } else {
    setStatus(Estatus.upload);
    setWrongFormat(true);
  }
}

/**
 * Adds commas to a number for better readability.
 *
 * @param number - The number (or string representation of a number) to be formatted.
 * @returns The formatted number with commas.
 */
export const numAddComma = (number: any): string | any => {
  if (typeof number === 'string' || number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return number;
}

/**
 * Checks the authentication status of the user.
 * If the user is not authenticated, redirects them to the login page.
 */
export const checkAuth = async () => {
  const response = await authCheck();
  if (response && response.applied.toString() !== 'true') {
    document.location.href = (window as any).env.MAIN_HOST + '/accounts/login/';
  }
};