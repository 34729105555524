import React from 'react';
import { Flex, Image, Link, Text,
  Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next';

import SchoolTab from '../Components/school/SchoolTab';
import chBanner from '../Resources/Images/Banners/ch_issuer_banner.png';
import enBanner from '../Resources/Images/Banners/en_issuer_banner.png';
import eduIcon from '../Resources/Images/Logos/edu_logo.svg';
import CenterGuideBrick from '../Components/Common/CenterGuideBrick';

const SchoolPage: React.FC = () => {
  const { t, i18n } = useTranslation();

  return (
    <Flex w='100%' minH='84vh' direction='column' align='center'>
      <Image w='100%' maxH='219px' objectFit='cover' src={i18n.language === 'zh' ? chBanner : enBanner} alt='教育部標章'/>
      <Flex w='85%' h='100%' py='32px' direction='column'>
        <CenterGuideBrick/>
        <Tabs variant='primary'>
          <TabList>
            <Tab textStyle='semibold_md'>{t('school.degreeCertificate')}</Tab>
            <Tab textStyle='semibold_md'>{t('school.teacherCertificate')}</Tab>
          </TabList>
          <TabPanels>
            <TabPanel p='35px 0'>
              <Text mb='20px' textStyle='bold_lg' color='text.main'>學位證書發證單位</Text>
              <SchoolTab />
            </TabPanel>
            <TabPanel p='35px 0'>
              <Text mb='20px' textStyle='bold_lg' color='text.main'>教師證書發證單位</Text>
              <Flex style={cardStyle}>
                <Image h='100%' mr='16px' src={eduIcon} alt='教育部 logo'/>
                <Link href='https://www.edu.tw' target='_blank'
                  _hover={{ color: '#078385' }}
                >
                  {t('school.edu')}
                </Link>
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </Flex>
  )
};

export default SchoolPage;

const cardStyle = {
  width: '48%',
  minWidth: '400px',
  height: '88px',
  padding: '16px 10px',
  margin: '15px 0px',
  alignItems: 'center',
  boxShadow: '4px 8px 10px 0 rgba(194, 211, 220, 0.15), -4px -2px 6px 0 rgba(194, 211, 220, 0.25)',
  borderRadius: '10px'
}