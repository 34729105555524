import React, { useEffect } from 'react';
import { Flex, Text, Stack, Input, InputGroup, InputRightElement,
  RadioGroup, Radio, Textarea, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { SmallCloseIcon } from '@chakra-ui/icons';

import { BulletinType } from '../../Utils/backStageApi';

interface Props {
  initData?: BulletinType;
  setFormData: React.Dispatch<React.SetStateAction<BulletinType | undefined>>;
}

const BulletinEditer: React.FC<Props> = ({
  initData,
  setFormData
}) => {
  const validate = (values: any) => {
    const errors: any = {};
    if(!values.title){
      errors.title = '請填寫標題'
    }
    if(!values.text){
      errors.text = '請填寫內容'
    }
    return errors;
  };
  const onSubmit = () => {};
  const formik = useFormik({
    initialValues: {
      bid: -1,
      date: '',
      title: '',
      tag: '一般公告',
      text: ''
    } as BulletinType,
    validate,
    onSubmit,
  });

  useEffect(() => {
    if (initData) {
      formik.setValues(initData);
    }
  }, [initData]);
  useEffect(() => {
    setFormData(formik.values);
  }, [formik]);
  
  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing='50px'>
        <Flex gap='30px'>
          <Text minW='50px' fontWeight='600' fontSize='24px' lineHeight='133%'>
            標題
          </Text>
          <FormControl isInvalid={!!formik.errors.title}>
            <InputGroup>
              <Input
                type='text'
                name='title'
                placeholder=''
                size='lg'
                onChange={formik.handleChange}
                value={formik.values.title}
              />
              {formik.values.title && (
                <InputRightElement
                  color='primary.main'
                  cursor={'pointer'}
                  onClick={() => formik.setFieldValue('title', '')}
                  children={<SmallCloseIcon color='primary.main' />}
                />
              )}
            </InputGroup>
            <FormErrorMessage>{formik.errors.title}</FormErrorMessage>
          </FormControl>
        </Flex>
        <Flex gap='30px'>
          <Text minW='50px' fontWeight='600' fontSize='24px' lineHeight='133%'>
            分類
          </Text>
          <RadioGroup name='tag' value={formik.values.tag}>
            <Stack spacing='24px' direction='row'>
              <Radio size='lg' colorScheme='telegram' textStyle='normal_md'
                value='一般公告' onChange={formik.handleChange}
              >
                一般公告
              </Radio>
              <Radio size='lg' colorScheme='telegram' textStyle='normal_md'
                value='合作學校' onChange={formik.handleChange}
              >
                合作學校
              </Radio>
              <Radio size='lg' colorScheme='telegram' textStyle='normal_md'
                value='系統維護' onChange={formik.handleChange}
              >
                系統維護
              </Radio>
            </Stack>
          </RadioGroup>
        </Flex>
        <Flex gap='30px'>
          <Text minW='50px' fontWeight='600' fontSize='24px' lineHeight='133%'>
            內容
          </Text>
          <FormControl isInvalid={!!formik.errors.text}>
            <Textarea
              h='600px'
              name='text'
              value={formik.values.text}
              onChange={formik.handleChange}
              placeholder=''
              size='lg'
            />
            <FormErrorMessage>{formik.errors.text}</FormErrorMessage>
          </FormControl>
        </Flex>
      </Stack>      
    </form>
  );
};

export default BulletinEditer;
