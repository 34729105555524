import { Button, Modal, ModalOverlay, ModalContent, 
  ModalHeader, ModalFooter, ModalBody } from '@chakra-ui/react';

interface simplePropsType {
  isOpen: boolean,
  onClose: () => void,
  header: JSX.Element | undefined,
  body?: JSX.Element | undefined,
  buttonName?: string,
  buttonFunc?: () => void
};

interface confirmPropsType {
  isOpen: boolean,
  onClose: () => void,
  header: JSX.Element | undefined,
  body?: JSX.Element | undefined,
  confirmButtonName?: string,
  confirmButtonFunc?: () => void,
  cancelButtonName?: string,
  cancelButtonFunc?: () => void
};

export const SimpleModal = (props: simplePropsType) => {
  return(
    <Modal isOpen={props.isOpen} onClose={props.onClose} size='xl' isCentered>
      <ModalOverlay />
      <ModalContent maxW='540px'>
        <ModalHeader p='24px 24px 16px 24px'>{props.header}</ModalHeader>
        {props.body && 
          <ModalBody p='0 24px 16px 24px' color='grey.700'>
            {props.body}
          </ModalBody>
        }
        <ModalFooter p='0 24px 24px 24px'>
          <Button onClick={props.buttonFunc ? props.buttonFunc : props.onClose}>
            {props.buttonName ? props.buttonName : '確定'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const ConfirmModal = (props: confirmPropsType) => {
  return(
    <Modal isOpen={props.isOpen} onClose={props.onClose} size='xl' isCentered>
      <ModalOverlay />
      <ModalContent maxW='540px'>
        <ModalHeader p='24px 24px 16px 24px'>{props.header}</ModalHeader>
        {props.body && 
          <ModalBody p='0 24px 16px 24px' color='grey.700'>
            {props.body}
          </ModalBody>
        }
        <ModalFooter p='0 24px 24px 24px' gap='8px'>
          <Button variant='secondary' onClick={props.cancelButtonFunc ? props.cancelButtonFunc : props.onClose}>
            {props.cancelButtonName ? props.cancelButtonName : '取消'}
          </Button>
          <Button onClick={props.confirmButtonFunc ? props.confirmButtonFunc : props.onClose}>
            {props.confirmButtonName ? props.confirmButtonName : '確定'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const CancelModal = (props: confirmPropsType) => {
  return(
    <Modal isOpen={props.isOpen} onClose={props.onClose} size='xl' isCentered>
      <ModalOverlay />
      <ModalContent maxW='540px'>
        <ModalHeader p='24px 24px 16px 24px'>{props.header}</ModalHeader>
        {props.body && 
          <ModalBody p='0 24px 16px 24px' color='grey.700'>
            {props.body}
          </ModalBody>
        }
        <ModalFooter p='0 24px 24px 24px' gap='8px'>
          <Button variant='secondary' onClick={props.cancelButtonFunc ? props.cancelButtonFunc : props.onClose}>
            {props.cancelButtonName ? props.cancelButtonName : '繼續'}
          </Button>
          <Button variant='critical'
            onClick={props.confirmButtonFunc ? props.confirmButtonFunc : props.onClose}
          >
            {props.confirmButtonName ? props.confirmButtonName : '取消'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};