import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

const BackStageFooter: React.FC = () => {
  return (
    <Flex as='footer' position='absolute' bottom='0' right='0' w='100%' p='38px 32px'
      justifyContent='end' alignItems='center'
      borderTop='1px solid' borderColor='grey.light'
    >
      <Text color='grey.700' textStyle='normal_sm'>教育部 電子證書驗證系統 後台管理平台 版權所有</Text>
    </Flex>
  )
}

export default BackStageFooter;