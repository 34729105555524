import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const ProgressBar: React.FC<{ status: number }> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <Flex w='100%' mt='24px' mb='40px' direction='column' align='center'>
      <Flex w='80%' justify='center'>
        <Flex flexGrow={1} direction='column' align='center'>
          <Flow>
            <Line color='#4986B0'/>
            <Circle done={(status >= 0).toString()}>1</Circle>
            <Line color={status > 0 && '#4986B0'} />
          </Flow>
          <Text mt='8px' textStyle='semibold_sm'>{t('upload.upload')}</Text>
        </Flex>
        <Flex flexGrow={1} direction='column' align='center'>
          <Flow>
            <Line color={status > 0 && '#4986B0'}/>
            <Circle done={(status >= 1).toString()}>2</Circle>
            <Line color={status > 1 && '#4986B0'} />
          </Flow>
          <Text mt='8px' textStyle='semibold_sm'>{t('upload.status')}</Text>
        </Flex>
        <Flex flexGrow={1} direction='column' align='center'>
          <Flow>
            <Line color={status > 1 && '#4986B0'}/>
            <Circle done={(status >= 2).toString()}>3</Circle>
            <Line color={status > 1 && '#4986B0'} />
          </Flow>
          <Text mt='8px' textStyle='semibold_sm'>{t('upload.result')}</Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ProgressBar

const Circle = styled(Flex)`
  justify-content: center;
  width: 20px;
  height: 20px;
  align-items: center;
  color: ${props => (props.done === 'true' ? '#FFFFFF' : '#4986B0')};
  border-radius: 50%;
  background: ${props => (props.done === 'true' ? '#4986B0' : '#DCE3E9')};
`;

const Flow = styled(Flex)`
  width: 100%;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
`
const Line = styled(Flex)`
  width: 50%;
  height: 1px;
  background: ${props => props.color || '#DCE3E9'};
`