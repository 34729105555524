import React, { useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import ProgressBar from '../Components/upload/ProgressBar';
import UploadSection from '../Components/upload/UploadSection';
import Instruction from '../Components/upload/Instruction';
import BulletinBoard from '../Components/upload/BulletinBoard';
import Statistics from '../Components/upload/Statistics';
import CenterGuideBrick from '../Components/Common/CenterGuideBrick';

const UploadPage: React.FC = () => {
  const [status, setStatus] = useState<number>(0);
  const { t, i18n } = useTranslation();

  return (
    <Flex w='100%' minH='84vh' direction='column' align='center'>
      <Flex w='85%' h='100%' direction='column' pt='30px' pb='60px'>
        <CenterGuideBrick/>
        <Flex>
          <Text textStyle='bold_lg'>{t('upload.verification')}</Text>
        </Flex>
        <ProgressBar status={status} />
        <UploadSection status={status} setStatus={setStatus}/>
        <Instruction />
        {i18n.language === 'zh' && <BulletinBoard />}
        <Statistics />
      </Flex>
    </Flex>
  )
};

export default UploadPage;
