import React, { useState, useEffect } from 'react';
import { Box, Flex, Stack, Text, Link as WebLink,
  Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { Link, useHistory } from 'react-router-dom';

import { getBulletinList, getDocument } from '../../Utils/frontStageApi';
import { BulletinListType, DocumentType } from '../../Utils/backStageApi';
import useMedia from '../../Utils/useMedia';

export const Hashtag: React.FC<{ text: string }> = ({ text }) => {
  const tagColor: { [key: string]: string } = {
    '合作學校': '#CBE3DB',
    '系統維護': '#BFDCE9',
    '一般公告': '#DAE0E5'
  };
  const backgroundColor = tagColor[text] || '#DAE0E5';

  return (
    <Flex maxW='120px' p='6px 21.5px' justify='center' alignItems='center' 
      bg={backgroundColor} borderRadius='3px'>
      <Text color='text.main' textStyle='bold_sm' wordBreak='keep-all'>
        {text}
      </Text>
    </Flex>
  );
}

const BulletinBoard: React.FC = () => {
  const history = useHistory();
  const [bulletins, setbulletins] = useState<BulletinListType>();
  const [documents, setDocuments] = useState<DocumentType[]>();
  const { isMobile, isDesktop, isTablet } = useMedia();

  useEffect(() => {
    getBulletinList(3)
    .then((data: any) => {
      if (data && data.list) {
        setbulletins(data.list);
      }
    });
    getDocument(3)
    .then((data: any) => {
      if (data && data.list) {
        data.list.map((item: DocumentType) =>{
          item.link = (window as any).env.MAIN_HOST + item.link;
          return item;
        });
        setDocuments(data.list);
      }
    });
  }, []);

  const announceBlock = () => {
    return (
        <Stack w={isDesktop ? '48%' : '100%'} p='25px' mt='5px' mb='5px' spacing='22px' bg='#E5F1F6' borderRadius='20px'>
          <Flex justify='space-between' alignItems='center'>
            <Flex gap='5px' alignItems='center'>
              <Box w='10px' h='15px' bg='primary.main' borderRadius='5px' />
              <Text textStyle='semibold_lg' color='primary.main'>計畫公告</Text>
            </Flex>
            <Link to='/news#announcement' target='_self'>
              <Text color='primary.dark' textStyle='semibold_sm'>查看更多 ＞</Text>
            </Link>
          </Flex>
          <Table w='100%' bg='white'>
            <Thead>
              <Tr>
                <Th p='10px 12px' color='text.main' fontSize='14px' fontWeight='600'>公告日期</Th>
                <Th p='10px 12px' color='text.main' fontSize='14px' fontWeight='600'>公告主旨</Th>
                <Th p='10px 12px' color='text.main' fontSize='14px' fontWeight='600' maxW='150px'>公告分類</Th>
              </Tr>
            </Thead>
            <Tbody>
              {bulletins && bulletins.map(item => (
                <Tr h='60px' key={item.bid} cursor='pointer'
                  onClick={() => history.push('/news#announcement', { bid: item.bid })}
                >
                  <Td p='10px 12px' color='text.main' textStyle='semibold_sm'>
                    {item.date}
                  </Td>
                  <Td p='10px 12px' color='text.main' textStyle='semibold_sm'>
                    {item.title}
                  </Td>
                  <Td p='6px 10px' color='text.main' textStyle='bold_sm' borderBottom='none'>
                    <Hashtag text={item.tag}/>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Stack>
    );
  }

  const documentBlock = () => {
    return (
        <Stack w={isDesktop ? '48%' : '100%'} p='25px' spacing='22px' bg='#DDEEE8' borderRadius='20px'>
          <Flex justify='space-between' alignItems='center'>
            <Flex gap='5px' alignItems='center'>
              <Box w='10px' h='15px' bg='#2A7157' borderRadius='5px' />
              <Text textStyle='semibold_lg' color='#2A7157'>文件下載</Text>
            </Flex>
            <Link to='/news#document' target='_self'>
              <Text color='primary.dark' textStyle='semibold_sm'>查看更多 ＞</Text>
            </Link>
          </Flex>
          <Table w='100%' bg='white'>
            <Thead>
              <Tr>
                <Th p='10px 12px' color='text.main' fontSize='14px' fontWeight='600' width='25%'>公告日期</Th>
                <Th p='10px 12px' color='text.main' fontSize='14px' fontWeight='600'>公告檔案</Th>
              </Tr>
            </Thead>
            <Tbody>
              {documents && documents.map((item) => (
                <Tr h='60px' key={item.did}>
                  <Td p='10px 12px' color='text.main' textStyle='semibold_sm'>
                    {item.date}                    
                  </Td>
                  <Td p='10px 12px' color='text.main' textStyle='semibold_sm'>
                    <WebLink href={item.link} target='_blank'>{item.title}</WebLink>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Stack>
    );
  }

  return (
    <Stack mt='50px' spacing='30px'>
      <Flex w='100%' justify='start'>
        <Text textStyle='semibold_lg' color='secondary.main'>最新消息</Text>
      </Flex>
      {isDesktop  && <Flex w='100%' justify='space-between'>{announceBlock()}{documentBlock()}</Flex>}
      {!isDesktop && <Flex w='100%' direction='column'>{announceBlock()}{documentBlock()}</Flex>}
    </Stack>
  )
}

export default BulletinBoard;