import React from 'react'

type Props={
  style?:{};
}

const CenterGuideBrick:React.FC<Props> = ({style={}}:Props) => {
  return (
    <a href="#C" title="中央內容區塊" id="AC" accessKey="C" style={style}>:::</a>
  )
}

export default CenterGuideBrick